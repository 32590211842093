import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY } from "../../helpers/constants";

export const logout = () => {
    // console.log("logout");
    localStorage.removeItem("loggedInAdminUser");

    return {
        type: actionTypes.LOGOUT
    };
};

const LOGOUT_MUTATION_TEMPLATE = `
mutation AdminUserLogout {
  adminUserLogout
}`;

const logoutMutation = (adminUserToken) => {
    // console.log("logoutMutation");
    // console.log(adminUserToken);

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: LOGOUT_MUTATION_TEMPLATE,
    });
};

export const logoutAsync = (adminUserToken) => {
    return (dispatch, getState) => {
        // console.log("logoutAsync");

        // Make Http call here and add .then(dispatch(logout(logoutCredentials)));
        logoutMutation(adminUserToken).then((response) => {
            // console.log(response);

            if (response.data.errors != null) {
                console.error(JSON.stringify(response.data.errors));
            }
            
            dispatch(logout());
        });
    };
};