import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const deleteRedemptionItemSuccess = (redemptionItemId) => {
    return {
        type: actionTypes.DELETE_REDEMPTION_ITEM_SUCCESS,
        payload: redemptionItemId,
    };
};

const deleteRedemptionItemError = (message) => {
    return {
        type: actionTypes.DELETE_REDEMPTION_ITEM_ERROR,
        payload: message,
    };
};

export const deleteRedemptionItemReset = () => {
    return {
        type: actionTypes.DELETE_REDEMPTION_ITEM_RESET,
    };
};

const DELETE_REDEMPTION_ITEM_MUTATION_TEMPLATE = `
mutation RedemptionItemDelete($redemptionItemId: Int!) {
    redemptionItemDelete(redemptionItemId: $redemptionItemId)   
}`;

const deleteRedemptionItemMutation = (adminUserToken, redemptionItemId) => {
    // console.log("deleteRedemptionItemMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: DELETE_REDEMPTION_ITEM_MUTATION_TEMPLATE,
        variables: { redemptionItemId: redemptionItemId },
    });
};

export const deleteRedemptionItemAsync = (adminUserToken, redemptionItemId) => {
    return (dispatch) => {
        deleteRedemptionItemMutation(adminUserToken, redemptionItemId).then(
            (response) => {
                // console.log(response);
                if (response.data.errors == null) {
                    dispatch(deleteRedemptionItemSuccess(redemptionItemId));
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    } else {
                        dispatch(
                            deleteRedemptionItemError(response.data.errors)
                        );
                    }
                }
            }
        );
    };
};
