import React, { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import * as styles from "../../assets/css/DetailStyles.module.scss";
import { NavLink } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import { connect } from "react-redux";
import {
    SUCCESS,
    ERROR,
} from "../../helpers/constants";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

const QRScan = (props) => {
    const alert = useAlert();

    // State
    const [scanResult, setScanResult] = useState({});

    const claimRedemptionRequestReset = props.claimRedemptionRequestReset;
    const claimRedemptionRequestId = props.claimRedemptionRequestId;
    const claimRedemptionRequestStatus = props.claimRedemptionRequestStatus;
    const claimRedemptionRequestError = props.claimRedemptionRequestError;

    const handleScan = (data) => {
        if (data) {
            var claimRedeemedItemInput = JSON.parse(data);
            setScanResult(claimRedeemedItemInput);

            props.claimRedemptionRequestAsync(
                props.adminUserToken,
                claimRedeemedItemInput
            );
        }
    };

    const handleError = (err) => {
        console.error(err);
        alert.error(err);
        props.claimRedemptionRequestReset();
    };

    let history = useHistory();

    // Update Alert Functionality
    useEffect(() => {
        // console.log("[QRScan] - useEffect");

        if (
            claimRedemptionRequestStatus === SUCCESS &&
            claimRedemptionRequestId != null
        ) {
            alert.success(
                `${getDisplayId(
                    claimRedemptionRequestId,
                    "# QR",
                    "0",
                    6
                )} claimed`
            );

            history.push(`/qrScanAndRecord`);
        } else if (claimRedemptionRequestStatus === ERROR) {
            console.error(claimRedemptionRequestError.map(x => x.message).join(", "));
            alert.error("Error Occured");
            alert.info(
                claimRedemptionRequestError.map((x) => x.message).join(", ")
            );
            history.push(`/qrScanAndRecord`);
        }

        return () => {
            // console.log("[QRScan] - Clean-up function in useEffect");
            claimRedemptionRequestReset();
        };
    }, [
        claimRedemptionRequestId,
        claimRedemptionRequestStatus,
        claimRedemptionRequestError,
        claimRedemptionRequestReset,
    ]);

    return (
        <>
            <div className={styles.serviceBlock}>
                <div className={styles.leftDiv}>
                    <h2>QR Scan</h2>
                    <div className={styles.adminDate}>
                        <NavLink to={"/qrScanAndRecord"} exact>
                            &lt; back to QR Scan & Record List
                        </NavLink>
                    </div>
                </div>
                <div className={styles.clear}></div>
            </div>
            <div className={styles.horizTableBlock}>
                <QrReader
                    delay={0}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "50%" }}
                />
                {/* <p>{JSON.stringify(scanResult)}</p> */}
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
        claimRedemptionRequestId: state.redemption.claimRedemptionRequestId,
        claimRedemptionRequestStatus:
            state.redemption.claimRedemptionRequestStatus,
        claimRedemptionRequestError:
            state.redemption.claimRedemptionRequestError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        claimRedemptionRequestAsync: (adminUserToken, claimRedeemedItemInput) =>
            dispatch(
                actions.claimRedemptionRequestAsync(
                    adminUserToken,
                    claimRedeemedItemInput
                )
            ),
        claimRedemptionRequestReset: () =>
            dispatch(actions.claimRedemptionRequestReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRScan);