import React from "react";
import * as styles from "./Sort.module.css";

const Sort = (props) => {
    // useEffect(() => {
    //     console.log("[Sort] - useEffect");
    //     // Http Requests
    
    //     // Clean-up function (optional, remove it if its not required)
    //     return () => {
    //         console.log("[Sort] - Clean-up function in useEffect")
    //     }
    // }, [Add dependencies here OR keep it empty array to run the effect only once OR remove array to run the effect with every render cycle]);

    // console.log("props.selectList");
    // console.log(props.selectList);

    return (
        <div className={styles.sortBy}>
            <label>Sort by:</label>
            <select onChange={(event) => props.selectionChanged(event.target.value)}>
                {
                    props.selectList.map((item, index) => {
                    return (
                        <option
                            key={item.key}
                            value={item.sortBy}
                        >
                            {item.displayValue}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

export default Sort;