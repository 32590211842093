import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const updateCampaignSuccess = (campaignId) => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_SUCCESS,
        payload: campaignId,
    };
};

const updateCampaignError = (message) => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_ERROR,
        payload: message,
    };
};

export const updateCampaignReset = () => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_RESET,
    };
};

const UPDATE_CAMPAIGN_MUTATION_TEMPLATE = `
mutation CampaignUpdate($campaignDetails: CampaignUpdateInput!) {
    campaign: campaignUpdate(campaignDetails: $campaignDetails) {
        campaignId
    }
}`;

const updateCampaignMutation = (adminUserToken, campaign) => {
    // console.log("updateCampaignMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: UPDATE_CAMPAIGN_MUTATION_TEMPLATE,
        variables: { campaignDetails: campaign },
    });
};

export const updateCampaignAsync = (adminUserToken, campaign) => {
    return (dispatch) => {
        updateCampaignMutation(adminUserToken, campaign).then((response) => {
            // console.log(response);
            if (response.data.errors == null) {
                dispatch(
                    updateCampaignSuccess(
                        response.data.data.campaign.campaignId
                    )
                );
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                } else {
                    dispatch(updateCampaignError(response.data.errors));
                }
            }
        });
    };
};
