import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import * as styles from "./ContentPlaceholder.module.css";

// Member Components
import MemberActivities from "../../MemberActivities/MemberActivities";

// Campaign Components
import CampaignCreate from "../../CampaignCreate/CampaignCreate";
import CampaignListing from "../../CampaignListing/CampaignListing";
import CampaignDetail from "../../CampaignDetail/CampaignDetail";
import CampaignUpdate from "../../CampaignUpdate/CampaignUpdate";

// Redemption Item Components
import RedemptionItemCreate from "../../RedemptionItemCreate/RedemptionItemCreate";
import RedemptionItemDetail from "../../RedemptionItemDetail/RedemptionItemDetail";
import RedemptionItemListing from "../../RedemptionItemListing/RedemptionItemListing";
import RedemptionItemUpdate from "../../RedemptionItemUpdate/RedemptionItemUpdate";

import QRScan from "../../QRScan/QRScan";
import QRScanAndRecord from "../../QRScanAndRecord/QRScanAndRecord";
import Home from "../../Home/Home";

// import PageNotFound from "../../PageNotFound/PageNotFound";

class ContentPlaceholder extends Component {
    constructor(props) {
        // console.log("[ContentPlaceholder] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[ContentPlaceholder] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    render() {
        // console.log("[ContentPlaceholder] render");

        return (
            <div className={styles.rightBox}>
                <Switch>
                    <Route path="/home" exact component={Home} />
                    <Route
                        path="/memberActivities"
                        exact
                        component={MemberActivities}
                    />
                    {/* <Route
                        path="/memberDetail/:memberId"
                        exact
                        component={MemberDetail}
                    /> */}
                    <Route
                        path="/campaignListing"
                        exact
                        component={CampaignListing}
                    />
                    <Route
                        path="/campaignDetail/:campaignId"
                        exact
                        component={CampaignDetail}
                    />
                    <Route
                        path="/campaignUpdate/:campaignId"
                        exact
                        component={CampaignUpdate}
                    />
                    <Route
                        path="/campaignCreate"
                        exact
                        component={CampaignCreate}
                    />
                    <Route
                        path="/redemptionItemCreate"
                        exact
                        component={RedemptionItemCreate}
                    />
                    <Route
                        path="/redemptionItemDetail/:redemptionItemId"
                        exact
                        component={RedemptionItemDetail}
                    />
                    <Route
                        path="/redemptionItemUpdate/:redemptionItemId"
                        exact
                        component={RedemptionItemUpdate}
                    />
                    <Route
                        path="/redemptionItemListing"
                        exact
                        component={RedemptionItemListing}
                    />
                    <Route
                        path="/qrScanAndRecord"
                        exact
                        component={QRScanAndRecord}
                    />
                    <Route
                        path="/qrScan"
                        exact
                        component={QRScan}
                    />
                    <Route path="/" component={Home} />
                    {/* <Route path="/" component={PageNotFound} /> */}
                </Switch>
            </div>
        );
    }
}

export default ContentPlaceholder;