import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as styles from "./CampaignListing.module.scss";
import * as commonStyles from "../../assets/css/commonStyles.module.css";

import { connect } from "react-redux";
import Moment from "react-moment";
import Search from "../../components/UI/Search/Search";
import Sort from "../../components/UI/Sort/Sort";
import Pagination from "../../components/UI/Pagination/pagination";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import * as paginationHelper from "../../helpers/pagination-helper";
import * as constansts from "../../helpers/constants";
import arraySort from "array-sort";

class CampaignListing extends Component {
    constructor(props) {
        // console.log("[CampaignListing] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
            sortSelectItems: [
                {
                    key: "campaignId",
                    displayValue: "# Campaign ID",
                    sortBy: "campaignId",
                },
                {
                    key: "image",
                    displayValue: "Campaign Display Picture",
                    sortBy: "image",
                },
                {
                    key: "name",
                    displayValue: "Campaign Name",
                    sortBy: "name",
                },
                {
                    key: "startDate",
                    displayValue: "Start Date (DD/MM/YYYY)",
                    sortBy: "startDate",
                },
                {
                    key: "endDate",
                    displayValue: "End Date (DD/MM/YYYY)",
                    sortBy: "endDate",
                },
                {
                    key: "campaignStatus",
                    displayValue: "Status",
                    sortBy: "campaignStatus.campaignStatusName",
                },
            ],
            searchString: "",
            sortString: "",
            reverse: false,
            pageSize: constansts.PageSize,
            selectedPageNumber: 1,
            listItemHeaders: [
                { displayValue: "# Campaign ID", sortBy: "campaignId" },
                { displayValue: "Campaign Display Picture", sortBy: "image" },
                { displayValue: "Campaign Name", sortBy: "name" },
                { displayValue: "Start Date (DD/MM/YYYY)", sortBy: "startDate" },
                { displayValue: "End Date (DD/MM/YYYY)", sortBy: "endDate" },
                {
                    displayValue: "Status",
                    sortBy: "campaignStatus.campaignStatusName",
                },
            ],
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[CampaignListing] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    pageSelectionChangeHandler = (newSelectedPageNumber) => {
        this.setState({ selectedPageNumber: newSelectedPageNumber });
    };

    previousPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber - 1 };
        });
    };

    nextPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber + 1 };
        });
    };

    filterListItems = (listItems, searchString) => {
        if (listItems != null) {
            return listItems.filter((item) => {
                return (
                    item.name
                        .toLowerCase()
                        .indexOf(searchString.toLowerCase()) !== -1
                );
            });
        }
    };

    filterAndSortListItems = (listItems, searchString, sortString) => {
        return arraySort(
            this.filterListItems(listItems, searchString),
            sortString.split(","),
            {reverse: this.state.reverse}
        );
    };

    searchStringChangedHandler = (newSearchString) => {
        // console.log("----- searchStringChangedHandler -----");
        this.setState((prevState, props) => {
            const filteredListItems = this.filterListItems(
                this.props.campaigns,
                newSearchString,
                prevState.sortString
            );

            const noOfPages = paginationHelper.getNoOfPages(
                filteredListItems.length,
                prevState.pageSize
            );

            return {
                searchString: newSearchString,
                selectedPageNumber: Math.min(
                    noOfPages,
                    prevState.selectedPageNumber === 0
                        ? 1
                        : prevState.selectedPageNumber
                ),
            };
        });
    };

    sortStringChangedHandler = (newSortString) => {
        // console.log("newSortString: " + newSortString);

        this.setState((prevState, props) => {
            return {
                sortString: newSortString,
                reverse: prevState.sortString === newSortString ? !prevState.reverse : false
            };
        });
    };

    render() {
        // console.log("[CampaignListing] render");

        if (this.props.campaigns == null) {
            return <div className={styles.serviceBlock}>Loading...</div>;
        } else {
            const filteredAndSortedListItems = this.filterAndSortListItems(
                this.props.campaigns,
                this.state.searchString,
                this.state.sortString
            );

            return (
                <>
                    <div className={styles.serviceBlock}>
                        <div className={styles.leftDiv}>
                            <h2>Campaign Management</h2>
                            <div className={styles.adminDate}>
                                <span>Total no. of Campaigns:</span>{" "}
                                {this.props.campaigns.length}
                            </div>
                        </div>
                        <div className={styles.rightDiv}>
                            {" "}
                            <NavLink
                                to={"/campaignCreate"}
                                exact
                                className={styles.createNew}
                            >
                                <span>Create New</span>
                            </NavLink>
                        </div>
                        <div className={commonStyles.clear}></div>
                    </div>
                    <div className={styles.sortingDiv}>
                        <Search
                            searchString={this.state.searchString}
                            searchStringChanged={
                                this.searchStringChangedHandler
                            }
                        />
                        <Sort
                            selectList={this.state.sortSelectItems}
                            selectionChanged={this.sortStringChangedHandler}
                        />
                        <div className={commonStyles.clear}></div>
                    </div>
                    <div className={styles.tableBlock}>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className={styles.actTabel}
                        >
                            <tbody>
                                <tr className={styles.first}>
                                    {this.state.listItemHeaders.map(
                                        (itemHeader, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    onClick={() =>
                                                        this.sortStringChangedHandler(
                                                            itemHeader.sortBy
                                                        )
                                                    }
                                                    className={
                                                        styles.headerSort
                                                    }
                                                >
                                                    <nobr>
                                                        {itemHeader.displayValue}
                                                        <a href="#">
                                                            <i
                                                                className={
                                                                    styles.sorting
                                                                }
                                                            ></i>
                                                        </a>
                                                    </nobr>
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                                {filteredAndSortedListItems
                                    .slice(
                                        paginationHelper.getStartItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        ),
                                        paginationHelper.getEndItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        )
                                    )
                                    .map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 === 1
                                                        ? styles.light
                                                        : ""
                                                }
                                            >
                                                <td>
                                                    <NavLink
                                                        to={`/campaignDetail/${item.campaignId}`}
                                                    >
                                                        {getDisplayId(
                                                            item.campaignId,
                                                            "# C",
                                                            "0",
                                                            6
                                                        )}
                                                    </NavLink>
                                                </td>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.campThumb
                                                        }
                                                    >
                                                        <NavLink
                                                            to={`/campaignDetail/${item.campaignId}`}
                                                        >
                                                            <img
                                                                src={item.imageUrl
                                                                    .replace(
                                                                        "{headerKey}",
                                                                        "AdminUserToken"
                                                                    )
                                                                    .replace(
                                                                        "{headerValue}",
                                                                        this
                                                                            .props
                                                                            .adminUserToken
                                                                    )}
                                                                alt=""
                                                            />
                                                        </NavLink>
                                                    </div>
                                                    <span
                                                        className={
                                                            styles.imageName
                                                        }
                                                    >
                                                        {item.image}
                                                    </span>
                                                </td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.startDate}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.endDate}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <a
                                                        href="#"
                                                        className={
                                                            item.campaignStatus
                                                                .campaignStatusName ===
                                                            "Active"
                                                                ? styles.statusActive
                                                                : styles.statusExpired
                                                        }
                                                    >
                                                        {
                                                            item.campaignStatus
                                                                .campaignStatusName
                                                        }
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {/* Table End */}
                    <Pagination
                        selectedPageNumber={this.state.selectedPageNumber}
                        noOfItems={filteredAndSortedListItems.length}
                        pageSize={this.state.pageSize}
                        pageSelectionChanged={this.pageSelectionChangeHandler}
                        previousPageClicked={this.previousPageClickHandler}
                        nextPageClicked={this.nextPageClickHandler}
                    />
                    {/* Pagination End */}
                </>
            );
        }
    }

    componentDidMount() {
        this.props.loadCampaignsAsync(this.props.adminUserToken);
    }
}

const mapStateToProps = (state) => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
        campaigns: state.campaign.campaigns,
        loadCampaignsError: state.campaign.loadCampaignsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCampaignsAsync: (adminUserToken) => dispatch(actions.loadCampaignsAsync(adminUserToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignListing);