import React, { Component } from "react";
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';
import Login from "../Login/Login";
import Layout from "../Layout/Layout";
import * as actions from "../../store/actions/actions";
import { withAlert } from "react-alert";

class App extends Component {
    constructor(props) {
        // console.log("[App] constructor");
        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[App] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    render() {
        // console.log("[App] render");
        if (this.props.loginErrors) {
            this.props.alert.error(this.props.loginErrors.map((x) => x.message));
        }
        return (
            <BrowserRouter>
                <div className="App">
                    {this.props.loggedInAdminUser ? <Layout /> : <Login />}
                </div>
            </BrowserRouter>
        );
    }
    componentDidMount() {
        this.props.autoLogin();
    }
}

const mapStateToProps = state => {
    return {
        loggedInAdminUser: state.auth.loggedInAdminUser,
        loginErrors: state.auth.loginErrors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        autoLogin: () => dispatch(actions.autoLogin()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(App));
