import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

export const uploadCampaignProfilePhotoReset = () => {
    return {
        type: actionTypes.UPLOAD_CAMPAIGN_PROFILE_PHOTO_RESET
    };
};

const uploadCampaignProfilePhotoSuccess = (uploadedFileName) => {
    return {
        type: actionTypes.UPLOAD_CAMPAIGN_PROFILE_PHOTO_SUCCESS,
        payload: uploadedFileName,
    };
};

export const uploadCampaignProfilePhotoAsync = (
    adminUserToken,
    uploadCampaignProfilePhotoPayload
) => {

    // console.log("uploadCampaignProfilePhotoAsync");
    // console.log(uploadCampaignProfilePhotoPayload);
    
    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";

    return (dispatch) => {
        dispatch(uploadCampaignProfilePhotoReset());

        axiosInstance
            .post(
                "api/image/uploadCampaignProfilePhoto",
                uploadCampaignProfilePhotoPayload
            )
            .then((response) => {
                if (response.data.errors == null) {
                    dispatch(
                        uploadCampaignProfilePhotoSuccess(response.data.data)
                    );
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    } else {
                        uploadCampaignProfilePhotoReset();
                    }
                }
            });
    };
};