import React from "react";

import "./Layout.css";
import Aux from "../../hoc/Aux";
import Header from "./Header/Header";
import LeftNavigation from "./LeftNavigation/LeftNavigation";
import ContentPlaceholder from "./ContentPlaceholder/ContentPlaceholder";

const layout = (props) => {
    return (
        <Aux>
            {/* <h1>This is a Layout Component</h1>
            <div>Toolbar, SideDrawer, BackDrop</div>
            <main className={classes.Content}>
                {props.children}
            </main> */}
            <div>
                <Header></Header>
                <LeftNavigation></LeftNavigation>
                <ContentPlaceholder></ContentPlaceholder>
            </div>
        </Aux>
    );
}

export default layout;