import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadProductTypes = (productTypes) => {
    return {
        type: actionTypes.LOAD_PRODUCT_TYPES,
        payload: productTypes,
    };
};

const PRODUCT_TYPES_QUERY_TEMPLATE = `
query ProductTypes {
  productTypes {
    productTypeId
    name
  }
}`;

const productTypesQuery = (adminUserToken) => {
    // console.log("productTypesQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: PRODUCT_TYPES_QUERY_TEMPLATE,
    });
};

export const loadProductTypesAsync = (adminUserToken) => {
    return (dispatch) => {
        productTypesQuery(adminUserToken).then((response) => {
            if (response.data.errors == null) {
                dispatch(loadProductTypes(response.data.data.productTypes));
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                }
            }
        });
    };
};
