import React, { useEffect } from "react";
// import * as styles2 from "./RedemptionItemUpdate.module.scss";
import * as styles from "../../assets/css/DetailStyles.module.scss";
import { NavLink } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import cn from "classnames";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import { useForm, Controller } from "react-hook-form";
import {
    ERROR_BACKGROUND_COLOR,
    SUCCESS,
    ERROR,
} from "../../helpers/constants";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { getReactAppAPIBaseUrl } from "../../helpers/common";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const RedemptionItemUpdate = (props) => {
    const alert = useAlert();

    useEffect(() => {
        // console.log("[RedemptionItemUpdate] - useEffect");
        // console.log(props);
        // Http Requests
        props.loadRedemptionItemAsync(
            props.adminUserToken,
            props.match.params.redemptionItemId
        );

        // Clean-up function (optional, remove it if its not required)
        return () => {
            // console.log("[RedemptionItemUpdate] - Clean-up function in useEffect");
        };
    }, []);

    const redemptionItem = props.selectedRedemptionItem;
    const uploadRedemptionItemProfilePhotoReset =
        props.uploadRedemptionItemProfilePhotoReset;
    // Update
    const updateRedemptionItemStatus = props.updateRedemptionItemStatus;
    const updateRedemptionItemError = props.updateRedemptionItemError;
    const updateRedemptionItemReset = props.updateRedemptionItemReset;
    // Delete
    const deleteRedemptionItemStatus = props.deleteRedemptionItemStatus;
    const deleteRedemptionItemError = props.deleteRedemptionItemError;
    const deleteRedemptionItemReset = props.deleteRedemptionItemReset;

    const defaultSponsorOption = { value: null, label: "Select a Sponsor" };
    const defaultCountryOption = { value: null, label: "Select a Country" };
    const defaultProductTypeOption = { value: null, label: "Select a Product Type" };

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        control,
        errors,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
    });

    const validateStartDate = (recursive = true) => {
        console.log("validateStartDate");
        const startDate = getValues("startDate");
        const endDate = getValues("endDate");
        if (!!startDate && !!endDate) {
            if (new Date(startDate) > new Date(endDate)) {
                setError("startDate", {
                    type: "manual",
                    message:
                        "Start Date should be less than or equal to End Date",
                });
                setError("endDate", {
                    type: "manual",
                    message:
                        "End Date should be greater than or equal to End Date",
                });
                console.log("Start Date - Error");
                return false;
            } else {
                console.log("Start Date - Clear Error");
                clearErrors("startDate");
                if (recursive) {
                    if (validateExpiryDate(false)) {
                        clearErrors("endDate");
                    }
                }

                return true;
            }
        } else {
            return true;
        }
    };

    const validateExpiryDate = (recursive) => {
        console.log("validateExpiryDate");
        const endDate = getValues("endDate");
        const expiryDate = getValues("expiryDate");

        if (!!endDate && !!expiryDate) {
            if (new Date(endDate) > new Date(expiryDate)) {
                setError("expiryDate", {
                    type: "manual",
                    message:
                        "Expiry Date should be greater than or equal to End Date",
                });
                setError("endDate", {
                    type: "manual",
                    message:
                        "End Date should be less than or equal to Expiry Date",
                });
                console.log("Expiry Date - Error");
                return false;
            } else {
                clearErrors("expiryDate");
                console.log("Expiry Date - Clear Error");
                if (recursive) {
                    if (validateStartDate(false)) {
                        clearErrors("endDate");
                    }
                }
                return true;
            }
        } else {
            return true;
        }
    };

    const validateEndDate = () => {
        return validateStartDate() && validateExpiryDate();
    };

    useEffect(() => {
        // console.log("[RedemptionItemUpdate] - useEffect 2");
        // console.log(props);
        if (redemptionItem) {
            // console.log(" ----- Redemption Item to be Updated ----- ");
            // console.log(redemptionItem);

            reset({
                redemptionItemId: redemptionItem.redemptionItemId,
                name: redemptionItem.name,
                description: redemptionItem.description,
                termsAndConditions: redemptionItem.termsAndConditions,
                supplierName: redemptionItem.supplierName,
                image: redemptionItem.image,
                startDate: new Date(redemptionItem.startDate),
                endDate: new Date(redemptionItem.endDate),
                expiryDate: new Date(redemptionItem.expiryDate),
                points: redemptionItem.points,
                availableStock: redemptionItem.availableStock,
                sponsorId: redemptionItem.sponsorId,
                countryId: redemptionItem.countryId,
                productTypeId: redemptionItem.productTypeId,
            });
        }

        // Clean-up function (optional, remove it if its not required)
        // return () => {
        //     console.log(
        //         "[RedemptionItemUpdate] - Clean-up function in useEffect 2"
        //     );
        // };
    }, [redemptionItem]);

    const fileChangeHandler = (event) => {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        props.uploadRedemptionItemProfilePhotoAsync(props.adminUserToken, data);
    };

    const onSubmit = (data) => {
        // console.log("----- FORM DATA -----");
        // console.log(data);
        props.updateRedemptionItemAsync(props.adminUserToken, data);
    };

    const onReset = () => {
        reset();
        props.uploadRedemptionItemProfilePhotoReset();
    };

    const onDelete = () => {
        confirmAlert({
            title: "Delete Promotion",
            message: "Are you sure you want to delete the Promotion?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () =>
                        props.deleteRedemptionItemAsync(
                            props.adminUserToken,
                            redemptionItem.redemptionItemId
                        ),
                },
                {
                    label: "No",
                    onClick: () => { }
                },
            ],
        });
    };
  
    const getSelectedSponsorOption = (sponsorId) => {
        const selectedSponsor = props.sponsors.find(
            (sponsor) => sponsor.sponsorId === sponsorId
        );
        if (selectedSponsor) {
            return {
                value: selectedSponsor.sponsorId,
                label: selectedSponsor.name,
            };
        } else {
            return defaultSponsorOption;
        }
    };

    const getSelectedCountryOption = (countryId) => {
        const selectedCountry = props.countries.find(
            (country) => country.countryId === countryId
        );
        if (selectedCountry) {
            return {
                value: selectedCountry.countryId,
                label: selectedCountry.name,
            };
        } else {
            return defaultCountryOption;
        }
    };

    const getSelectedProductTypeOption = (productTypeId) => {
        const selectedProductType = props.productTypes.find(
            (productType) => productType.productTypeId === productTypeId
        );
        if (selectedProductType) {
            return {
                value: selectedProductType.productTypeId,
                label: selectedProductType.name,
            };
        } else {
            return defaultProductTypeOption;
        }
    };

    useEffect(() => {
        if (props.sponsors == null) {
            props.loadSponsorsAsync(props.adminUserToken);
        }
        if (props.countries == null) {
            props.loadCountriesAsync(props.adminUserToken);
        }
        if (props.productTypes == null) {
            props.loadProductTypesAsync(props.adminUserToken);
        }
    }, []);

    useEffect(() => {
        // Clean-up function (optional, remove it if its not required)
        return () => {
            uploadRedemptionItemProfilePhotoReset();
        };
    }, [uploadRedemptionItemProfilePhotoReset]);

    let history = useHistory();

    // Update Alert Functionality
    useEffect(() => {
        if (updateRedemptionItemStatus === SUCCESS && redemptionItem != null) {
            alert.success(
                `${getDisplayId(
                    redemptionItem.redemptionItemId,
                    "# P",
                    "0",
                    6
                )} updated`
            );

            history.push(
                `/redemptionItemDetail/${redemptionItem.redemptionItemId}`
            );
        } else if (updateRedemptionItemStatus === ERROR) {
            console.error(
                updateRedemptionItemError.map((x) => x.message).join(", ")
            );
            alert.error("Error Occured");
        }

        return () => {
            updateRedemptionItemReset();
        };
    }, [
        redemptionItem,
        updateRedemptionItemStatus,
        updateRedemptionItemError,
        updateRedemptionItemReset,
    ]);

    // Delete Alert Functionality
    useEffect(() => {
        // console.log("[RedemptionItemUpdate] - useEffect 5");

        if (deleteRedemptionItemStatus === SUCCESS && redemptionItem != null) {
            alert.success(
                `${getDisplayId(
                    redemptionItem.redemptionItemId,
                    "# P",
                    "0",
                    6
                )} deleted`
            );

            history.push(`/redemptionItemListing`);
        } else if (deleteRedemptionItemStatus === ERROR) {
            console.error(
                deleteRedemptionItemError.map((x) => x.message).join(", ")
            );
            alert.error("Error Occured");
        }

        return () => {
            deleteRedemptionItemReset();
        };
    }, [
        redemptionItem,
        deleteRedemptionItemStatus,
        deleteRedemptionItemError,
        deleteRedemptionItemReset,
    ]);

    if (redemptionItem == null) {
        return <div className={styles.serviceBlock}>Loading...</div>;
    } else {
        var bgImage =
            props.uploadRedemptionItemProfileImage == null ||
            props.uploadRedemptionItemProfileImage === ""
                ? redemptionItem.image
                : props.uploadRedemptionItemProfileImage;

        var backgroundImageStyle = {
            backgroundImage: `url(${getReactAppAPIBaseUrl()}api/image/getRedemptionItemProfilePhoto?fileName=${bgImage}&headerKey=adminUserToken&headerValue=${
                props.adminUserToken
            })`,
        };

        return (
            <>
                <div className={styles.serviceBlock}>
                    <div className={styles.leftDiv}>
                        <h2>Redemption Management</h2>
                        <div className={styles.adminDate}>
                            <NavLink to={"/redemptionItemListing"} exact>
                                &lt; back to Redemption Management List
                            </NavLink>
                        </div>
                    </div>
                    <div className={styles.rightDiv}>
                        <div className={styles.delete}>
                            <input
                                type="button"
                                value="Delete Promotion"
                                onClick={onDelete}
                            />
                        </div>
                    </div>
                    <div className={styles.clear}></div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.horizTableBlock}>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className={styles.actTabel}
                        >
                            <tbody>
                                <tr>
                                    <td className={styles.th}>
                                        # Promotion ID
                                    </td>
                                    <td>
                                        {getDisplayId(
                                            redemptionItem.redemptionItemId,
                                            "# P",
                                            "0",
                                            6
                                        )}
                                        <input
                                            name="redemptionItemId"
                                            ref={register({
                                                required: true,
                                            })}
                                            type="hidden"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Promotion Display Picture<sup>*</sup>
                                    </td>
                                    <td className={styles.first}>
                                        <div
                                            className={styles.fileUpload}
                                            style={
                                                errors.image
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.imageUploadWrap
                                                }
                                                style={backgroundImageStyle}
                                            >
                                                <div
                                                    className={styles.dragText}
                                                >
                                                    <h3>&nbsp;</h3>
                                                </div>
                                                <input
                                                    name="image"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                    type="hidden"
                                                    value={bgImage}
                                                />
                                                <input
                                                    name="file"
                                                    className={
                                                        styles.fileUploadInput
                                                    }
                                                    type="file"
                                                    onChange={fileChangeHandler}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Promotion Name<sup>*</sup>
                                    </td>
                                    <td>
                                        <input
                                            name="name"
                                            ref={register({ required: true })}
                                            type="text"
                                            className={styles.input}
                                            style={
                                                errors.name
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Item Offered by<sup>*</sup>
                                    </td>
                                    <td className={styles.first}>
                                        <input
                                            name="supplierName"
                                            ref={register({ required: true })}
                                            type="text"
                                            className={styles.input}
                                            style={
                                                errors.supplierName
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Item Description<sup>*</sup> & Terms
                                        <sup>*</sup>
                                    </td>
                                    <td className={styles.heightBig}>
                                        <span className={styles.leftCont}>
                                            <h3>
                                                Description<sup>*</sup>:
                                            </h3>
                                            {/* {props.redemptionItem.description} */}
                                            <textarea
                                                name="description"
                                                ref={register({
                                                    required: true,
                                                })}
                                                type="text"
                                                className={styles.textarea}
                                                style={
                                                    errors.description
                                                        ? {
                                                              backgroundColor:
                                                                  ERROR_BACKGROUND_COLOR,
                                                          }
                                                        : {}
                                                }
                                            />
                                        </span>
                                        <span className={styles.rightCont}>
                                            <h3>
                                                Terms<sup>*</sup>:
                                            </h3>
                                            <textarea
                                                name="termsAndConditions"
                                                ref={register({
                                                    required: true,
                                                })}
                                                type="text"
                                                className={styles.textarea}
                                                style={
                                                    errors.termsAndConditions
                                                        ? {
                                                              backgroundColor:
                                                                  ERROR_BACKGROUND_COLOR,
                                                          }
                                                        : {}
                                                }
                                            />
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Sponsor Name<sup>*</sup>
                                    </td>
                                    <td className={styles.first}>
                                        {props.sponsors && (
                                            <Controller
                                                control={control}
                                                name="sponsorId"
                                                defaultValue={null}
                                                rules={{ required: true }}
                                                render={({
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                }) => (
                                                    <Dropdown
                                                        value={getSelectedSponsorOption(
                                                            value
                                                        )}
                                                        options={[
                                                            defaultSponsorOption,
                                                        ].concat(
                                                            props.sponsors.map(
                                                                (sponsor) => {
                                                                    return {
                                                                        value: sponsor.sponsorId,
                                                                        label: sponsor.name,
                                                                    };
                                                                }
                                                            )
                                                        )}
                                                        controlClassName={
                                                            errors.sponsorId
                                                                ? styles.error
                                                                : ""
                                                        }
                                                        onChange={(
                                                            selectedSponsor
                                                        ) =>
                                                            onChange(
                                                                (value =
                                                                    selectedSponsor.value)
                                                            )
                                                        }
                                                        onBlur={onBlur}
                                                    />
                                                )}
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Country Name<sup>*</sup>
                                    </td>
                                    <td>
                                        {props.countries && (
                                            <Controller
                                                control={control}
                                                name="countryId"
                                                defaultValue={null}
                                                rules={{ required: true }}
                                                render={({
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                }) => (
                                                    <Dropdown
                                                        value={getSelectedCountryOption(
                                                            value
                                                        )}
                                                        options={[
                                                            defaultCountryOption,
                                                        ].concat(
                                                            props.countries.map(
                                                                (country) => {
                                                                    return {
                                                                        value: country.countryId,
                                                                        label: country.name,
                                                                    };
                                                                }
                                                            )
                                                        )}
                                                        controlClassName={
                                                            errors.countryId
                                                                ? styles.error
                                                                : ""
                                                        }
                                                        onChange={(
                                                            selectedCountry
                                                        ) =>
                                                            onChange(
                                                                (value =
                                                                    selectedCountry.value)
                                                            )
                                                        }
                                                        onBlur={onBlur}
                                                    />
                                                )}
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Product Type Name
                                    </td>
                                    <td className={styles.first}>
                                        {props.productTypes && (
                                            <Controller
                                                control={control}
                                                name="productTypeId"
                                                defaultValue={null}
                                                rules={{ required: false }}
                                                render={({
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                }) => (
                                                    <Dropdown
                                                        value={getSelectedProductTypeOption(
                                                            value
                                                        )}
                                                        options={[
                                                            defaultProductTypeOption,
                                                        ].concat(
                                                            props.productTypes.map(
                                                                (
                                                                    productType
                                                                ) => {
                                                                    return {
                                                                        value: productType.productTypeId,
                                                                        label: productType.name,
                                                                    };
                                                                }
                                                            )
                                                        )}
                                                        onChange={(
                                                            selectedProductType
                                                        ) =>
                                                            onChange(
                                                                (value =
                                                                    selectedProductType.value)
                                                            )
                                                        }
                                                        onBlur={onBlur}
                                                    />
                                                )}
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Period (DD/MM/YYYY)<sup>*</sup>
                                    </td>
                                    <td>
                                        <div className={styles.calenderWrapper}>
                                            <Controller
                                                control={control}
                                                name="startDate"
                                                defaultValue={null}
                                                rules={{
                                                    required: true,
                                                    validate: validateStartDate,
                                                }}
                                                render={({
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                }) => (
                                                    <ReactDatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(value) =>
                                                            onChange(value)
                                                        }
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        className={
                                                            errors.startDate
                                                                ? styles.calenderError
                                                                : styles.calender
                                                        }
                                                        wrapperClassName={
                                                            styles.calenderPopper01
                                                        }
                                                        placeholderText="DD/MM/YYYY"
                                                    />
                                                )}
                                            />
                                            <b className={styles.spaceTo}>to</b>
                                            <Controller
                                                control={control}
                                                name="endDate"
                                                defaultValue={null}
                                                rules={{
                                                    required: true,
                                                    validate: validateEndDate,
                                                }}
                                                render={({
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                }) => (
                                                    <ReactDatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={(value) =>
                                                            onChange(value)
                                                        }
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        className={
                                                            errors.endDate
                                                                ? styles.calenderError
                                                                : styles.calender
                                                        }
                                                        wrapperClassName={
                                                            styles.calenderPopper02
                                                        }
                                                        placeholderText="DD/MM/YYYY"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Expiry Date (DD/MM/YYYY)<sup>*</sup>
                                    </td>
                                    <td className={styles.first}>
                                        <Controller
                                            control={control}
                                            name="expiryDate"
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                                validate: validateExpiryDate,
                                            }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    className={
                                                        errors.expiryDate
                                                            ? styles.calenderError
                                                            : styles.calender
                                                    }
                                                    placeholderText="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        V Points Value<sup>*</sup>
                                    </td>
                                    <td>
                                        <input
                                            name="points"
                                            ref={register({
                                                required: true,
                                                pattern: /\d+/,
                                            })}
                                            type="number"
                                            className={styles.input}
                                            style={
                                                errors.points
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        No. of Available Stock<sup>*</sup>
                                    </td>
                                    <td className={styles.first}>
                                        <input
                                            name="availableStock"
                                            ref={register({
                                                required: true,
                                                pattern: /\d+/,
                                            })}
                                            type="number"
                                            className={styles.input}
                                            style={
                                                errors.availableStock
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.th}>
                                        Status<sup>*</sup>
                                    </td>
                                    <td>
                                        <label className={styles.radioCont}>
                                            Active
                                            <input
                                                name="redemptionItemStatusId"
                                                ref={register({
                                                    required: true,
                                                })}
                                                style={
                                                    errors.redemptionItemStatusId
                                                        ? {
                                                              backgroundColor:
                                                                  ERROR_BACKGROUND_COLOR,
                                                          }
                                                        : {}
                                                }
                                                type="radio"
                                                defaultChecked={
                                                    redemptionItem.redemptionItemStatusId ===
                                                    1
                                                }
                                                value="1"
                                            />
                                            <span
                                                className={styles.checkmark}
                                            ></span>
                                        </label>
                                        <label className={styles.radioCont}>
                                            Expired
                                            <input
                                                name="redemptionItemStatusId"
                                                ref={register({
                                                    required: true,
                                                })}
                                                style={
                                                    errors.redemptionItemStatusId
                                                        ? {
                                                              backgroundColor:
                                                                  ERROR_BACKGROUND_COLOR,
                                                          }
                                                        : {}
                                                }
                                                type="radio"
                                                defaultChecked={
                                                    redemptionItem.redemptionItemStatusId ===
                                                    2
                                                }
                                                value="2"
                                            />
                                            <span
                                                className={styles.checkmark}
                                            ></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* Table End */}
                    <div className={cn(styles.paginationBlock, styles.update)}>
                        <div className={styles.back}>
                            <input
                                type="button"
                                value="Cancel"
                                onClick={onReset}
                            ></input>
                        </div>
                        <div className={styles.next}>
                            <input type="submit" value="Save Changes" />
                        </div>
                        <div className={styles.clearfix}></div>
                    </div>
                </form>
            </>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
        selectedRedemptionItem: state.redemption.selectedRedemptionItem,
        uploadRedemptionItemProfileImage:
            state.redemption.uploadRedemptionItemProfileImage,
        // Update
        updateRedemptionItemStatus: state.redemption.updateRedemptionItemStatus,
        updateRedemptionItemError: state.redemption.updateRedemptionItemError,
        // Delete
        deleteRedemptionItemStatus: state.redemption.deleteRedemptionItemStatus,
        deleteRedemptionItemError: state.redemption.deleteRedemptionItemError,
        sponsors: state.lookup.sponsors,
        countries: state.lookup.countries,
        productTypes: state.lookup.productTypes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRedemptionItemAsync: (adminUserToken, redemptionItemId) =>
            dispatch(
                actions.loadRedemptionItemAsync(
                    adminUserToken,
                    redemptionItemId
                )
            ),
        uploadRedemptionItemProfilePhotoReset: () =>
            dispatch(actions.uploadRedemptionItemProfilePhotoReset()),
        uploadRedemptionItemProfilePhotoAsync: (
            adminUserToken,
            fileToBeUploaded
        ) =>
            dispatch(
                actions.uploadRedemptionItemProfilePhotoAsync(
                    adminUserToken,
                    fileToBeUploaded
                )
            ),
        updateRedemptionItemReset: () =>
            dispatch(actions.updateRedemptionItemReset()),
        updateRedemptionItemAsync: (adminUserToken, redemptionItem) =>
            dispatch(
                actions.updateRedemptionItemAsync(
                    adminUserToken,
                    redemptionItem
                )
            ),
        deleteRedemptionItemReset: () => dispatch(actions.deleteRedemptionItemReset()),
        deleteRedemptionItemAsync: (adminUserToken, redemptionItemId) =>
            dispatch(
                actions.deleteRedemptionItemAsync(
                    adminUserToken,
                    redemptionItemId
                )
            ),
        loadSponsorsAsync: (adminUserToken) => dispatch(actions.loadSponsorsAsync(adminUserToken)),
        loadCountriesAsync: (adminUserToken) => dispatch(actions.loadCountriesAsync(adminUserToken)),
        loadProductTypesAsync: (adminUserToken) => dispatch(actions.loadProductTypesAsync(adminUserToken)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionItemUpdate);
