import { updateObject } from "../utility";
import * as actionTypes from "../actions/actionTypes";
import { SUCCESS, ERROR } from "../../helpers/constants";

const initialState = {
    uploadCampaignProfileImage: "",
    // Create
    createCampaignId: null,
    createCampaignStatus: null,
    createCampaignError: null,
    // Update
    updateCampaignId: null,
    updateCampaignStatus: null,
    updateCampaignError: null,
    // Delete
    deleteCampaignId: null,
    deleteCampaignStatus: null,
    deleteCampaignError: null,
};

const campaignReducer = (state = initialState, action) => {
    let updatedStateSlice = {};

    switch (action.type) {
        case actionTypes.LOAD_CAMPAIGN:
            updatedStateSlice = {
                selectedCampaign: action.payload,
            };
            break;
        case actionTypes.LOAD_CAMPAIGNS:
            updatedStateSlice = {
                campaigns: action.payload,
            };
            break;
        case actionTypes.SELECT_CAMPAIGN:
            updatedStateSlice = {
                selectedCampaign: action.payload,
            };
            break;
        case actionTypes.UPLOAD_CAMPAIGN_PROFILE_PHOTO_RESET:
            updatedStateSlice = {
                uploadCampaignProfileImage: "",
            };
            break;
        case actionTypes.UPLOAD_CAMPAIGN_PROFILE_PHOTO_SUCCESS:
            updatedStateSlice = {
                uploadCampaignProfileImage: action.payload,
            };
            break;
        case actionTypes.CREATE_CAMPAIGN_SUCCESS:
            updatedStateSlice = {
                createCampaignStatus: SUCCESS,
                createCampaignId: action.payload,
            };
            break;
        case actionTypes.CREATE_CAMPAIGN_ERROR:
            updatedStateSlice = {
                createCampaignStatus: ERROR,
                createCampaignError: action.payload,
            };
            break;
        case actionTypes.CREATE_CAMPAIGN_RESET:
            updatedStateSlice = {
                createCampaignStatus: null,
                createCampaignId: null,
                createCampaignError: null,
            };
            break;
        case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
            updatedStateSlice = {
                updateCampaignStatus: SUCCESS,
                updateCampaignId: action.payload,
            };
            break;
        case actionTypes.UPDATE_CAMPAIGN_ERROR:
            updatedStateSlice = {
                updateCampaignStatus: ERROR,
                updateCampaignError: action.payload,
            };
            break;
        case actionTypes.UPDATE_CAMPAIGN_RESET:
            updatedStateSlice = {
                updateCampaignStatus: null,
                updateCampaignId: null,
                updateCampaignError: null,
            };
            break;
        case actionTypes.DELETE_CAMPAIGN_SUCCESS:
            updatedStateSlice = {
                deleteCampaignStatus: SUCCESS,
                deleteCampaignId: action.payload,
            };
            break;
        case actionTypes.DELETE_CAMPAIGN_ERROR:
            updatedStateSlice = {
                deleteCampaignStatus: ERROR,
                deleteCampaignError: action.payload,
            };
            break;
        case actionTypes.DELETE_CAMPAIGN_RESET:
            updatedStateSlice = {
                deleteCampaignId: null,
                deleteCampaignStatus: null,
                deleteCampaignError: null,
            };
            break;
        default:
    }

    return updateObject(state, updatedStateSlice);
};

export default campaignReducer;