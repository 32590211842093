import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import * as styles from "./MemberActivities.module.scss";
import * as commonStyles from "../../assets/css/commonStyles.module.css"

import Moment from "react-moment";
import * as actions from "../../store/actions/actions";
import Search from "../../components/UI/Search/Search";
import Sort from "../../components/UI/Sort/Sort";
import Pagination from "../../components/UI/Pagination/pagination";

import { getDisplayId } from "../../helpers/entity-helper";
import { adjustTimezone } from "../../helpers/common";

import * as paginationHelper from "../../helpers/pagination-helper";
import * as constansts from "../../helpers/constants";
import arraySort from "array-sort";

class MemberActivities extends Component {
    constructor(props) {
        // console.log("[MemberActivities] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
            sortSelectItems: [
                {
                    key: "memberId",
                    displayValue: "# Member ID",
                    sortBy: "memberId",
                },
                {
                    key: "firstName",
                    displayValue: "First Name",
                    sortBy: "firstName",
                },
                {
                    key: "lastName",
                    displayValue: "Last Name",
                    sortBy: "lastName",
                },
                {
                    key: "email",
                    displayValue: "Email",
                    sortBy: "email",
                },
                {
                    key: "mobileNumber",
                    displayValue: "Mobile",
                    sortBy: "countryCode,mobileNumber",
                },
                {
                    key: "dateOfBirth",
                    displayValue: "Date of Birth (DD/MM/YYYY)",
                    sortBy: "dateOfBirth",
                },
                {
                    key: "experience.experienceId",
                    displayValue: "Climbing Experience",
                    sortBy: "experience.experienceId",
                },
                {
                    key: "expertLevel.expertLevelId",
                    displayValue: "Expert Level",
                    sortBy: "expertLevel.expertLevelId",
                },
                {
                    key: "currentVPoints",
                    displayValue: "Current V Points",
                    sortBy: "currentVPoints",
                },
                {
                    key: "lastLoginDate",
                    displayValue: "Last Login (DD/MM/YYYY - Time)",
                    sortBy: "lastLoginDate",
                },
            ],
            searchString: "",
            sortString: "",
            reverse: false,
            pageSize: constansts.PageSize,
            selectedPageNumber: 1,
            listItemHeaders: [
                { displayValue: "# Member ID", sortBy: "memberId" },
                {
                    displayValue: "Display Name (First, Last)",
                    sortBy: "firstName,lastName",
                },
                { displayValue: "Email", sortBy: "email" },
                { displayValue: "Mobile", sortBy: "countryCode,mobileNumber" },
                {
                    displayValue: "Date of Birth (DD/MM/YYYY)",
                    sortBy: "dateOfBirth",
                },
                {
                    displayValue: "Climbing Exp. (Expert Lvl.)",
                    sortBy: "experience.experienceId,expertLevel.expertLevelId",
                },
                { displayValue: "Current V Points", sortBy: "currentVPoints" },
                {
                    displayValue: "Last Login (DD/MM/YYYY - Time)",
                    sortBy: "lastLoginDate",
                },
            ],
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log(
    //         "[MemberActivities] getDerivedStateFromProps",
    //         props,
    //         state
    //     );
    //     return state;
    // }

    pageSelectionChangeHandler = (newSelectedPageNumber) => {
        this.setState({ selectedPageNumber: newSelectedPageNumber });
    };

    previousPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber - 1 };
        });
    };

    nextPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber + 1 };
        });
    };

    filterListItems = (listItems, searchString) => {
        return listItems.filter((item) => {
            return (
                (item.firstName + " " + item.lastName + " " + item.email)
                    .toLowerCase()
                    .indexOf(searchString.toLowerCase()) !== -1
            );
        });
    };

    filterAndSortListItems = (listItems, searchString, sortString) => {
        return arraySort(
            this.filterListItems(listItems, searchString),
            sortString.split(","),
            { reverse: this.state.reverse }
        );
    };

    searchStringChangedHandler = (newSearchString) => {
        this.setState((prevState, props) => {
            const filteredListItems = this.filterListItems(
                // prevState.listItems,
                this.props.members,
                newSearchString,
                prevState.sortString
            );

            const noOfPages = paginationHelper.getNoOfPages(
                filteredListItems.length,
                prevState.pageSize
            );

            return {
                searchString: newSearchString,
                selectedPageNumber: Math.min(
                    noOfPages,
                    prevState.selectedPageNumber === 0
                        ? 1
                        : prevState.selectedPageNumber
                ),
            };
        });
    };

    sortStringChangedHandler = (newSortString) => {
        // console.log("newSortString: " + newSortString);

        this.setState((prevState, props) => {
            return {
                sortString: newSortString,
                reverse:
                    prevState.sortString === newSortString
                        ? !prevState.reverse
                        : false,
            };
        });
    };

    render() {
        // console.log("[MemberActivities] render");

        if (this.props.members == null) {
            return <div className={styles.serviceBlock}>Loading...</div>;
        } else {
            const filteredAndSortedListItems = this.filterAndSortListItems(
                // this.state.listItems,
                this.props.members,
                this.state.searchString,
                this.state.sortString
            );

            return (
                <>
                    <div className={styles.serviceBlock}>
                        <h2>Member Activities</h2>
                        <div className={styles.adminDate}>
                            <span>Total no. of Members: </span>{" "}
                            {this.props.members.length}
                        </div>
                    </div>
                    <div className={styles.sortingDiv}>
                        <Search
                            searchString={this.state.searchString}
                            searchStringChanged={
                                this.searchStringChangedHandler
                            }
                        />
                        <Sort
                            selectList={this.state.sortSelectItems}
                            selectionChanged={this.sortStringChangedHandler}
                        />
                        <div className={commonStyles.clear}></div>
                    </div>
                    <div className={styles.tableBlock}>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className={styles.actTabel}
                        >
                            <tbody>
                                <tr className={styles.first}>
                                    {this.state.listItemHeaders.map(
                                        (itemHeader, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    onClick={() =>
                                                        this.sortStringChangedHandler(
                                                            itemHeader.sortBy
                                                        )
                                                    }
                                                    className={styles.headerSort}
                                                >
                                                    <nobr>
                                                        {
                                                            itemHeader.displayValue
                                                        }
                                                        <a href="#">
                                                            <i
                                                                className={
                                                                    styles.sorting
                                                                }
                                                            ></i>
                                                        </a>
                                                    </nobr>
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                                {filteredAndSortedListItems
                                    .slice(
                                        paginationHelper.getStartItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        ),
                                        paginationHelper.getEndItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        )
                                    )
                                    .map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 === 1
                                                        ? styles.light
                                                        : ""
                                                }
                                            >
                                                <td>
                                                    <NavLink
                                                        to={`/memberDetail/${item.memberId}`}
                                                    >
                                                        {getDisplayId(
                                                            item.memberId,
                                                            "# M",
                                                            "0",
                                                            6
                                                        )}
                                                    </NavLink>
                                                    {/* <a href="redemption-management-detail.html">
                                                        {getDisplayId(
                                                            item.memberId,
                                                            "# M",
                                                            "0",
                                                            6
                                                        )}
                                                    </a> */}
                                                </td>
                                                <td>
                                                    {item.firstName},{" "}
                                                    {item.lastName}
                                                </td>
                                                <td>{item.email}</td>
                                                <td>
                                                    {item.countryCode}{" "}
                                                    {item.mobileNumber}
                                                </td>
                                                <td>
                                                    {item.dateOfBirth ==
                                                    null ? (
                                                        ""
                                                    ) : (
                                                        <Moment format="DD/MM/YYYY">
                                                            {item.dateOfBirth}
                                                        </Moment>
                                                    )}
                                                </td>
                                                <td>
                                                    {item.experience != null
                                                        ? item.experience
                                                              .experienceName
                                                        : ""}{" "}
                                                    {item.expertLevel != null
                                                        ? `(${item.expertLevel.expertLevelName})`
                                                        : ""}
                                                </td>
                                                <td>{item.credits}</td>
                                                <td>
                                                    {item.lastLoginDate ==
                                                    null ? (
                                                        ""
                                                    ) : (
                                                        <Moment format="DD/MM/YYYY - hh:mm">
                                                            {adjustTimezone(
                                                                new Date(
                                                                    item.lastLoginDate
                                                                )
                                                            )}
                                                        </Moment>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {/* Table End */}
                    <Pagination
                        selectedPageNumber={this.state.selectedPageNumber}
                        noOfItems={filteredAndSortedListItems.length}
                        pageSize={this.state.pageSize}
                        pageSelectionChanged={this.pageSelectionChangeHandler}
                        previousPageClicked={this.previousPageClickHandler}
                        nextPageClicked={this.nextPageClickHandler}
                    />
                    {/* pagination End */}
                </>
            );
        }
    }

    componentDidMount() {
        this.props.loadMembersAsync(this.props.adminUserToken);
    }
}

const mapStateToProps = state => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
        members: state.member.members,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadMembersAsync: (adminUserToken) =>
            dispatch(actions.loadMembersAsync(adminUserToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberActivities);