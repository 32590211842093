import { updateObject } from "../utility";
import * as actionTypes from '../../store/actions/actionTypes';

const initialState = {
    loggedInAdminUser: null,
};

const authReducer = (state = initialState, action) => {

    // console.log("authReducer");
    // console.log(action.type);
    // console.log(action.payload);

    let updatedStateSlice = {};

    switch (action.type) {
        case actionTypes.LOGIN:
            updatedStateSlice = {
                loggedInAdminUser: {
                    ...action.payload,
                    adminUser: {
                        ...action.payload.adminUser,
                    },
                },
                loginErrors: null,
            };
            break;

        case actionTypes.LOGIN_ERROR:
            updatedStateSlice = {
                loggedInAdminUser: null,
                loginErrors: [...action.payload]
            };
            break;

        case actionTypes.LOGOUT:
            updatedStateSlice = {
                loggedInAdminUser: null,
            };
            break;

        default:
    }

    return updateObject(state, updatedStateSlice);
};

export default authReducer;