// Login
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";

// Logout
export const LOGOUT = "LOGOUT";

// Campaign
export const LOAD_CAMPAIGN = "LOAD_CAMPAIGN";
export const LOAD_CAMPAIGNS = "LOAD_CAMPAIGNS";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";
export const CREATE_CAMPAIGN_RESET = "CREATE_CAMPAIGN_RESET";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_ERROR = "UPDATE_CAMPAIGN_ERROR";
export const UPDATE_CAMPAIGN_RESET = "UPDATE_CAMPAIGN_RESET";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_ERROR = "DELETE_CAMPAIGN_ERROR";
export const DELETE_CAMPAIGN_RESET = "DELETE_CAMPAIGN_RESET";
export const SELECT_CAMPAIGN = "SELECT_CAMPAIGN";
export const UPLOAD_CAMPAIGN_PROFILE_PHOTO_RESET =
    "UPLOAD_CAMPAIGN_PROFILE_PHOTO_RESET";
export const UPLOAD_CAMPAIGN_PROFILE_PHOTO_SUCCESS =
    "UPLOAD_CAMPAIGN_PROFILE_PHOTO_SUCCESS";

// Member
export const LOAD_MEMBERS = "LOAD_MEMBERS";

// Redemption Item
export const CREATE_REDEMPTION_ITEM_SUCCESS = "CREATE_REDEMPTION_ITEM_SUCCESS";
export const CREATE_REDEMPTION_ITEM_ERROR = "CREATE_REDEMPTION_ITEM_ERROR";
export const CREATE_REDEMPTION_ITEM_RESET = "CREATE_REDEMPTION_ITEM_RESET";
export const UPDATE_REDEMPTION_ITEM_SUCCESS = "UPDATE_REDEMPTION_ITEM_SUCCESS";
export const UPDATE_REDEMPTION_ITEM_ERROR = "UPDATE_REDEMPTION_ITEM_ERROR";
export const UPDATE_REDEMPTION_ITEM_RESET = "UPDATE_REDEMPTION_ITEM_RESET";
export const DELETE_REDEMPTION_ITEM_SUCCESS = "DELETE_REDEMPTION_ITEM_SUCCESS";
export const DELETE_REDEMPTION_ITEM_ERROR = "DELETE_REDEMPTION_ITEM_ERROR";
export const DELETE_REDEMPTION_ITEM_RESET = "DELETE_REDEMPTION_ITEM_RESET";
export const UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_RESET = "UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_RESET";
export const UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_SUCCESS = "UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_SUCCESS";
export const LOAD_REDEMPTION_ITEM = "LOAD_REDEMPTION_ITEM";
export const LOAD_REDEMPTION_ITEMS = "LOAD_REDEMPTION_ITEMS";

// Redemption Request
export const LOAD_REDEMPTION_REQUESTS = "LOAD_REDEMPTION_REQUESTS";
export const CLAIM_REDEMPTION_REQUEST_SUCCESS = "CLAIM_REDEMPTION_REQUEST_SUCCESS";
export const CLAIM_REDEMPTION_REQUEST_ERROR = "CLAIM_REDEMPTION_REQUEST_ERROR";
export const CLAIM_REDEMPTION_REQUEST_RESET = "CLAIM_REDEMPTION_REQUEST_RESET";

// Lookup data
export const LOAD_SPONSORS = "LOAD_SPONSORS";
export const LOAD_PRODUCT_TYPES = "LOAD_PRODUCT_TYPES";
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";