import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY } from "../../helpers/constants";

const claimRedemptionRequestSuccess = (redemptionRequestId) => {
    return {
        type: actionTypes.CLAIM_REDEMPTION_REQUEST_SUCCESS,
        payload: redemptionRequestId,
    };
};

const claimRedemptionRequestError = (message) => {
    return {
        type: actionTypes.CLAIM_REDEMPTION_REQUEST_ERROR,
        payload: message,
    };
};

export const claimRedemptionRequestReset = () => {
    return {
        type: actionTypes.CLAIM_REDEMPTION_REQUEST_RESET,
    };
};

const CLAIM_REDEMPTION_REQUEST_MUTATION_TEMPLATE = `
mutation ClaimRedeemedItem($claimRedeemedItemInput: ClaimRedeemedItemInput!) {
  redemptionRequest: claimRedeemedItem(claimRedeemedItemInput: $claimRedeemedItemInput) {
    redemptionRequestId
  }
}`;

const claimRedemptionRequestMutation = (
    adminUserToken,
    claimRedeemedItemInput
) => {
    console.log("claimRedemptionRequestMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: CLAIM_REDEMPTION_REQUEST_MUTATION_TEMPLATE,
        variables: {
            claimRedeemedItemInput: claimRedeemedItemInput
        },
    });
};

export const claimRedemptionRequestAsync = (adminUserToken, claimRedeemedItemInput) => {
    return (dispatch) => {
        
        // console.log("claimRedemptionRequestAsync");
        // console.log(claimRedeemedItemInput);

        claimRedemptionRequestMutation(
            adminUserToken,
            claimRedeemedItemInput
        ).then((response) => {
            // console.log(response);
            if (response.data.errors == null) {
                dispatch(
                    claimRedemptionRequestSuccess(
                        response.data.data.redemptionRequest.redemptionRequestId
                    )
                );
            } else {
                console.error(JSON.stringify(response.data.errors));
                dispatch(claimRedemptionRequestError(response.data.errors));
            }
        });
    };
};
