import React, { useEffect } from "react";
// import * as styles2 from "./RedemptionItemDetail.module.scss";
import * as styles from "../../assets/css/DetailStyles.module.scss";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import cn from "classnames";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import RedemptionRequestListing from "../RedemptionRequestListing/RedemptionRequestListing";

const RedemptionItemDetail = (props) => {

    useEffect(() => {
        // console.log("[RedemptionItemDetail] - useEffect");
        // console.log(props);
        // Http Requests
        props.loadRedemptionItemAsync(
            props.adminUserToken,
            props.match.params.redemptionItemId
        );

        // Clean-up function (optional, remove it if its not required)
        // return () => {
        //     console.log(
        //         "[RedemptionItemDetail] - Clean-up function in useEffect"
        //     );
        // };
    }, []);

    if (props.redemptionItem == null) {
        return <div className={styles.serviceBlock}>Loading...</div>;
    } else {
        return (
            <>
                <div className={styles.serviceBlock}>
                    <div className={styles.leftDiv}>
                        <h2>
                            Redemption Management - {props.redemptionItem.name}
                        </h2>
                        <div className={styles.adminDate}>
                            <NavLink to={"/redemptionItemListing"} exact>
                                &lt; back to Redemption Management List
                            </NavLink>
                        </div>
                    </div>
                    <div className={cn(styles.rightDiv, styles.edit)}>
                        <NavLink
                            to={`/redemptionItemUpdate/${props.redemptionItem.redemptionItemId}`}
                            exact
                            className={styles.createNew}
                        >
                            <span>Edit Promotion</span>
                        </NavLink>
                    </div>
                    <div className={styles.clear}></div>
                </div>
                <div className={styles.horizTableBlock}>
                    <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                    >
                        <tbody>
                            <tr>
                                <td className={styles.th}># Promotion ID</td>
                                <td>
                                    {getDisplayId(
                                        props.redemptionItem.redemptionItemId,
                                        "# P",
                                        "0",
                                        6
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Promotion Display Picture
                                </td>
                                <td className={styles.first}>
                                    <div className={styles.thumbBig}>
                                        <img
                                            src={props.redemptionItem.imageUrl
                                                .replace(
                                                    "{headerKey}",
                                                    "AdminUserToken"
                                                )
                                                .replace(
                                                    "{headerValue}",
                                                    props.adminUserToken
                                                )}
                                            alt=""
                                        />
                                    </div>
                                    <span className={styles.imageName}>
                                        {props.redemptionItem.image}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Promotion Name</td>
                                <td>{props.redemptionItem.name}</td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Item Offered by</td>
                                <td className={styles.first}>
                                    {props.redemptionItem.supplierName}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Item Description & Terms
                                </td>
                                <td className={styles.heightBig}>
                                    <span className={styles.leftCont}>
                                        <h3>Description:</h3>
                                        {props.redemptionItem.description}
                                    </span>
                                    <span className={styles.rightCont}>
                                        <h3>Terms:</h3>
                                        {
                                            props.redemptionItem
                                                .termsAndConditions
                                        }
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Sponsor Name</td>
                                <td className={styles.first}>
                                    {props.redemptionItem.sponsor != null
                                        ? props.redemptionItem.sponsor.name
                                        : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Country Name</td>
                                <td>
                                    {props.redemptionItem.country != null
                                        ? props.redemptionItem.country.name
                                        : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Product Type Name</td>
                                <td className={styles.first}>
                                    {props.redemptionItem.productType != null
                                        ? props.redemptionItem.productType.name
                                        : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Period (DD/MM/YYYY)
                                </td>
                                <td>
                                    <Moment format="DD/MM/YYYY">
                                        {props.redemptionItem.startDate}
                                    </Moment>{" "}
                                    <b className={styles.space}>to</b>{" "}
                                    <Moment format="DD/MM/YYYY">
                                        {props.redemptionItem.endDate}
                                    </Moment>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Expiry Date (DD/MM/YYYY)
                                </td>
                                <td className={styles.first}>
                                    <Moment format="DD/MM/YYYY">
                                        {props.redemptionItem.expiryDate}
                                    </Moment>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>V Points Value</td>
                                <td>{props.redemptionItem.points}</td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    No. of Available Stock
                                </td>
                                <td className={styles.first}>
                                    {props.redemptionItem.availableStock}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Status</td>
                                <td>
                                    <a
                                        href="#"
                                        className={
                                            props.redemptionItem
                                                .redemptionItemStatus
                                                .redemptionItemStatusName ===
                                            "Active"
                                                ? styles.statusActive
                                                : styles.statusExpired
                                        }
                                    >
                                        {
                                            props.redemptionItem
                                                .redemptionItemStatus
                                                .redemptionItemStatusName
                                        }
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <RedemptionRequestListing
                    redemptionItemId={props.match.params.redemptionItemId}
                ></RedemptionRequestListing>
            </>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        redemptionItem: state.redemption.selectedRedemptionItem,
        adminUserToken: state.auth.loggedInAdminUser.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRedemptionItemAsync: (adminUserToken, redemptionItemId) =>
            dispatch(
                actions.loadRedemptionItemAsync(
                    adminUserToken,
                    redemptionItemId
                )
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionItemDetail);
