import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const createRedemptionItemSuccess = (redemptionItemId) => {
    return {
        type: actionTypes.CREATE_REDEMPTION_ITEM_SUCCESS,
        payload: redemptionItemId,
    };
};

const createRedemptionItemError = (message) => {
    return {
        type: actionTypes.CREATE_REDEMPTION_ITEM_ERROR,
        payload: message,
    };
};

export const createRedemptionItemReset = () => {
    return {
        type: actionTypes.CREATE_REDEMPTION_ITEM_RESET,
    };
};

const CREATE_REDEMPTION_ITEM_MUTATION_TEMPLATE = `
mutation RedemptionItemCreate($redemptionItemDetails: RedemptionItemInput!) {
    redemptionItem: redemptionItemCreate(redemptionItemDetails: $redemptionItemDetails) {
        redemptionItemId
    }
}`;

const createRedemptionItemMutation = (adminUserToken, redemptionItem) => {
    // console.log("createRedemptionItemMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: CREATE_REDEMPTION_ITEM_MUTATION_TEMPLATE,
        variables: { redemptionItemDetails: redemptionItem },
    });
};

export const createRedemptionItemAsync = (adminUserToken, redemptionItem) => {
    return (dispatch) => {
        createRedemptionItemMutation(adminUserToken, redemptionItem).then(
            (response) => {
                // console.log(response);
                if (response.data.errors == null) {
                    dispatch(
                        createRedemptionItemSuccess(
                            response.data.data.redemptionItem.redemptionItemId
                        )
                    );
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    } else {
                        dispatch(
                            createRedemptionItemError(response.data.errors)
                        );
                    }
                }
            }
        );
    };
};
