import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import "./Header.css";
import logo from "../../../assets/images/logo-small.png";

class Header extends Component {
    constructor(props) {
        // console.log("[Header] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[Header] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    render() {
        // console.log("[Header] render");

        return (
            <header>
                <div className="logo">
                    <NavLink
                        to="/home"
                        exact
                        activeStyle={{}}
                        activeClassName="active"
                    >
                        <img src={logo} alt="Verm City" />
                    </NavLink>
                </div>
                <div className="welcome">Welcome, {this.props.loggedInUserName}</div>
                <div className="clear"></div>
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedInUserName: state.auth.loggedInAdminUser.adminUser.firstName,
    };
};

export default connect(mapStateToProps)(Header);