import React, { useEffect } from "react";
// import * as styles2 from "./RedemptionItemCreate.module.scss";
import * as styles from "../../assets/css/DetailStyles.module.scss";
import { NavLink } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import cn from "classnames";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import { useForm, Controller } from "react-hook-form";
import NoImage from "../../assets/images/no-image.png";
import {
    ERROR_BACKGROUND_COLOR,
    SUCCESS,
    ERROR,
} from "../../helpers/constants";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { getReactAppAPIBaseUrl } from "../../helpers/common";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const RedemptionItemCreate = (props) => {
    const alert = useAlert();

    const createRedemptionItemId = props.createRedemptionItemId;
    const createRedemptionItemStatus = props.createRedemptionItemStatus;
    const createRedemptionItemError = props.createRedemptionItemError;
    const uploadRedemptionItemProfilePhotoReset =
        props.uploadRedemptionItemProfilePhotoReset;
    const createRedemptionItemReset = props.createRedemptionItemReset;

    const defaultSponsorOption = { value: null, label: "Select a Sponsor" };
    const defaultCountryOption = { value: null, label: "Select a Country" };
    const defaultProductTypeOption = { value: null, label: "Select a Product Type" };
    
    const {
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        control,
        errors,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
    });

    const validateStartDate = (recursive = true) => {
        console.log("validateStartDate");
        const startDate = getValues("startDate");
        const endDate = getValues("endDate");
        if (!!startDate && !!endDate) {
            if (new Date(startDate) > new Date(endDate)) {
                setError("startDate", {
                    type: "manual",
                    message: "Start Date should be less than or equal to End Date",
                });
                setError("endDate", {
                    type: "manual",
                    message: "End Date should be greater than or equal to End Date",
                });
                console.log("Start Date - Error");
                return false;
            } else {
                console.log("Start Date - Clear Error");
                clearErrors("startDate");
                if (recursive) {
                    if (validateExpiryDate(false)) {
                        clearErrors("endDate");
                    }
                }
                
                return true;
            }
        }
        else {
            return true;
        }
    };

    const validateExpiryDate = (recursive) => {
        console.log("validateExpiryDate");
        const endDate = getValues("endDate");
        const expiryDate = getValues("expiryDate");

        if (!!endDate && !!expiryDate) {
            if (new Date(endDate) > new Date(expiryDate)) {
                setError("expiryDate", {
                    type: "manual",
                    message:
                        "Expiry Date should be greater than or equal to End Date",
                });
                setError("endDate", {
                    type: "manual",
                    message:
                        "End Date should be less than or equal to Expiry Date",
                });
                console.log("Expiry Date - Error");
                return false;
            } else {
                clearErrors("expiryDate");
                console.log("Expiry Date - Clear Error");
                if (recursive) {
                    if (validateStartDate(false)) {
                        clearErrors("endDate");
                    }
                }
                return true;
            }
        }
        else {
            return true;
        }
    };

    const validateEndDate = () => {
        return validateStartDate() && validateExpiryDate();
    };

    const fileChangeHandler = (event) => {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        props.uploadRedemptionItemProfilePhotoAsync(props.adminUserToken, data);
    };

    const onSubmit = (data) => {
        // console.log("----- FORM DATA -----");
        // console.log(data);
        props.createRedemptionItemAsync(props.adminUserToken, data);
    };

    const onReset = () => {
        reset({
            startDate: null,
            endDate: null,
            expiryDate: null,
            sponsorId: null,
            countryId: null,
            productTypeId: null,
        });
        props.uploadRedemptionItemProfilePhotoReset();
    };

    const getSelectedSponsorOption = (sponsorId) => {
        const selectedSponsor = props.sponsors.find(
            (sponsor) => sponsor.sponsorId === sponsorId
        );
        if (selectedSponsor) {
            return {
                value: selectedSponsor.sponsorId,
                label: selectedSponsor.name,
            };
        } else {
            return defaultSponsorOption;
        }
    };

    const getSelectedCountryOption = (countryId) => {
        const selectedCountry = props.countries.find(
            (country) => country.countryId === countryId
        );
        if (selectedCountry) {
            return {
                value: selectedCountry.countryId,
                label: selectedCountry.name,
            };
        } else {
            return defaultCountryOption;
        }
    };

    const getSelectedProductTypeOption = (productTypeId) => {
        const selectedProductType = props.productTypes.find(
            (productType) => productType.productTypeId === productTypeId
        );
        if (selectedProductType) {
            return {
                value: selectedProductType.productTypeId,
                label: selectedProductType.name,
            };
        } else {
            return defaultProductTypeOption;
        }
    };
  
    useEffect(() => {
        if (props.sponsors == null) {
            props.loadSponsorsAsync(props.adminUserToken);
        }
        if (props.countries == null) {
            props.loadCountriesAsync(props.adminUserToken);
        }
        if (props.productTypes == null) {
            props.loadProductTypesAsync(props.adminUserToken);
        }
    }, []);

    useEffect(() => {
        // Clean-up function (optional, remove it if its not required)
        return () => {
            uploadRedemptionItemProfilePhotoReset();
        };
    }, [uploadRedemptionItemProfilePhotoReset]);

    let history = useHistory();

    useEffect(() => {
        if (
            createRedemptionItemStatus === SUCCESS &&
            createRedemptionItemId != null
        ) {
            alert.success(
                `${getDisplayId(
                    createRedemptionItemId,
                    "# C",
                    "0",
                    6
                )} created successfully`
            );

            history.push(`/redemptionItemDetail/${createRedemptionItemId}`);
        } else if (createRedemptionItemStatus === ERROR) {
            console.log(createRedemptionItemError.map((x) => x.message).join(", "));
            alert.error("Error Occured");
        }

        return () => {
            // console.log("[RedemptionItemCreate] - Clean-up function in useEffect");
            createRedemptionItemReset();
        };
    }, [
        createRedemptionItemId,
        createRedemptionItemStatus,
        createRedemptionItemError,
        createRedemptionItemReset,
    ]);

    var backgroundImageStyle;
    if (
        props.uploadRedemptionItemProfileImage == null ||
        props.uploadRedemptionItemProfileImage === ""
    ) {
        backgroundImageStyle = {
            backgroundImage: `url(${NoImage})`,
        };
    } else {
        backgroundImageStyle = {
            backgroundImage: `url(${getReactAppAPIBaseUrl()}api/image/getRedemptionItemProfilePhoto?fileName=${
                props.uploadRedemptionItemProfileImage
            }&headerKey=adminUserToken&headerValue=${props.adminUserToken})`,
        };
    }
    return (
        <>
            <div className={styles.serviceBlock}>
                <div className={styles.leftDiv}>
                    <h2>Redemption Management</h2>
                    <div className={styles.adminDate}>
                        <NavLink to={"/redemptionItemListing"} exact>
                            &lt; back to Redemption Management List
                        </NavLink>
                    </div>
                </div>
                <div className={styles.clear}></div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.horizTableBlock}>
                    <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                        className={styles.actTabel}
                    >
                        <tbody>
                            {/* <tr>
                                <td className={styles.th}># RedemptionItem ID</td>
                                <td># C000001</td>
                            </tr> */}
                            <tr>
                                <td className={styles.th}>
                                    Promotion Display Picture<sup>*</sup>
                                </td>
                                <td className={styles.first}>
                                    <div
                                        className={styles.fileUpload}
                                        style={
                                            errors.image
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    >
                                        <div
                                            className={styles.imageUploadWrap}
                                            style={backgroundImageStyle}
                                        >
                                            <div className={styles.dragText}>
                                                <h3>&nbsp;</h3>
                                            </div>
                                            <input
                                                name="image"
                                                ref={register({
                                                    required: true,
                                                })}
                                                type="hidden"
                                                value={
                                                    props.uploadRedemptionItemProfileImage
                                                }
                                            />
                                            <input
                                                name="file"
                                                className={
                                                    styles.fileUploadInput
                                                }
                                                type="file"
                                                onChange={fileChangeHandler}
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Promotion Name<sup>*</sup>
                                </td>
                                <td>
                                    <input
                                        name="name"
                                        ref={register({ required: true })}
                                        type="text"
                                        className={styles.input}
                                        style={
                                            errors.name
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Item Offered by<sup>*</sup>
                                </td>
                                <td>
                                    <input
                                        name="supplierName"
                                        ref={register({ required: true })}
                                        type="text"
                                        className={styles.input}
                                        style={
                                            errors.supplierName
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Item Description<sup>*</sup> & Terms
                                    <sup>*</sup>
                                </td>
                                <td className={styles.heightBig}>
                                    <span className={styles.leftCont}>
                                        <h3>
                                            Description<sup>*</sup>:
                                        </h3>
                                        <textarea
                                            name="description"
                                            ref={register({ required: true })}
                                            type="text"
                                            className={styles.textarea}
                                            style={
                                                errors.description
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </span>
                                    <span className={styles.rightCont}>
                                        <h3>
                                            Terms<sup>*</sup>:
                                        </h3>
                                        <textarea
                                            name="termsAndConditions"
                                            ref={register({ required: true })}
                                            type="text"
                                            className={styles.textarea}
                                            style={
                                                errors.termsAndConditions
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                        />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Sponsor Name<sup>*</sup>
                                </td>
                                <td>
                                    {props.sponsors && (
                                        <Controller
                                            control={control}
                                            name="sponsorId"
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <Dropdown
                                                    value={getSelectedSponsorOption(
                                                        value
                                                    )}
                                                    options={[
                                                        defaultSponsorOption,
                                                    ].concat(
                                                        props.sponsors.map(
                                                            (sponsor) => {
                                                                return {
                                                                    value: sponsor.sponsorId,
                                                                    label: sponsor.name,
                                                                };
                                                            }
                                                        )
                                                    )}
                                                    controlClassName={
                                                        errors.sponsorId
                                                            ? styles.error
                                                            : ""
                                                    }
                                                    onChange={(
                                                        selectedSponsor
                                                    ) =>
                                                        onChange(
                                                            (value =
                                                                selectedSponsor.value)
                                                        )
                                                    }
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Country Name<sup>*</sup>
                                </td>
                                <td>
                                    {props.countries && (
                                        <Controller
                                            control={control}
                                            name="countryId"
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <Dropdown
                                                    value={getSelectedCountryOption(
                                                        value
                                                    )}
                                                    options={[
                                                        defaultCountryOption,
                                                    ].concat(
                                                        props.countries.map(
                                                            (country) => {
                                                                return {
                                                                    value: country.countryId,
                                                                    label: country.name,
                                                                };
                                                            }
                                                        )
                                                    )}
                                                    controlClassName={
                                                        errors.countryId
                                                            ? styles.error
                                                            : ""
                                                    }
                                                    onChange={(
                                                        selectedCountry
                                                    ) =>
                                                        onChange(
                                                            (value =
                                                                selectedCountry.value)
                                                        )
                                                    }
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Product Type Name</td>
                                <td>
                                    {props.productTypes && (
                                        <Controller
                                            control={control}
                                            name="productTypeId"
                                            defaultValue={null}
                                            rules={{ required: false }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <Dropdown
                                                    value={getSelectedProductTypeOption(
                                                        value
                                                    )}
                                                    options={[
                                                        defaultProductTypeOption,
                                                    ].concat(
                                                        props.productTypes.map(
                                                            (productType) => {
                                                                return {
                                                                    value: productType.productTypeId,
                                                                    label: productType.name,
                                                                };
                                                            }
                                                        )
                                                    )}
                                                    onChange={(
                                                        selectedProductType
                                                    ) =>
                                                        onChange(
                                                            (value =
                                                                selectedProductType.value)
                                                        )
                                                    }
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Period (DD/MM/YYYY)<sup>*</sup>
                                </td>
                                <td>
                                    <div className={styles.calenderWrapper}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                                validate: validateStartDate,
                                            }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    className={
                                                        errors.startDate
                                                            ? styles.calenderError
                                                            : styles.calender
                                                    }
                                                    wrapperClassName={
                                                        styles.calenderPopper01
                                                    }
                                                    placeholderText="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                        <b className={styles.spaceTo}>to</b>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                                validate: validateEndDate,
                                            }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    className={
                                                        errors.endDate
                                                            ? styles.calenderError
                                                            : styles.calender
                                                    }
                                                    wrapperClassName={
                                                        styles.calenderPopper02
                                                    }
                                                    placeholderText="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Expiry Date (DD/MM/YYYY)<sup>*</sup>
                                </td>
                                <td>
                                    <Controller
                                        control={control}
                                        name="expiryDate"
                                        defaultValue={null}
                                        rules={{
                                            required: true,
                                            validate: validateExpiryDate,
                                        }}
                                        render={({
                                            onChange,
                                            onBlur,
                                            value,
                                        }) => (
                                            <ReactDatePicker
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                onBlur={onBlur}
                                                selected={value}
                                                className={
                                                    errors.expiryDate
                                                        ? styles.calenderError
                                                        : styles.calender
                                                }
                                                placeholderText="DD/MM/YYYY"
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    V Points Value<sup>*</sup>
                                </td>
                                <td>
                                    <input
                                        name="points"
                                        ref={register({
                                            required: true,
                                            pattern: /\d+/,
                                        })}
                                        type="number"
                                        className={styles.input}
                                        style={
                                            errors.points
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    No. of Available Stock<sup>*</sup>
                                </td>
                                <td>
                                    <input
                                        name="availableStock"
                                        ref={register({
                                            required: true,
                                            pattern: /\d+/,
                                        })}
                                        type="number"
                                        className={styles.input}
                                        style={
                                            errors.availableStock
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Status<sup>*</sup>
                                </td>
                                <td className={styles.first}>
                                    <label className={styles.radioCont}>
                                        Active
                                        <input
                                            name="redemptionItemStatusId"
                                            ref={register({ required: true })}
                                            style={
                                                errors.redemptionItemStatusId
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                            type="radio"
                                            defaultChecked
                                            value="1"
                                        />
                                        <span
                                            className={styles.checkmark}
                                        ></span>
                                    </label>
                                    <label className={styles.radioCont}>
                                        Expired
                                        <input
                                            name="redemptionItemStatusId"
                                            ref={register({ required: true })}
                                            style={
                                                errors.redemptionItemStatusId
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                            type="radio"
                                            value="2"
                                        />
                                        <span
                                            className={styles.checkmark}
                                        ></span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Table End */}
                <div className={cn(styles.paginationBlock, styles.create)}>
                    <div className={styles.back}>
                        <input
                            type="button"
                            value="Cancel"
                            onClick={onReset}
                        ></input>
                    </div>
                    <div className={styles.next}>
                        <input type="submit" value="Save Changes" />
                    </div>
                    <div className={styles.clearfix}></div>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
        uploadRedemptionItemProfileImage:
            state.redemption.uploadRedemptionItemProfileImage,
        createRedemptionItemId: state.redemption.createRedemptionItemId,
        createRedemptionItemStatus: state.redemption.createRedemptionItemStatus,
        createRedemptionItemError: state.redemption.createRedemptionItemError,
        sponsors: state.lookup.sponsors,
        countries: state.lookup.countries,
        productTypes: state.lookup.productTypes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadRedemptionItemProfilePhotoReset: () =>
            dispatch(actions.uploadRedemptionItemProfilePhotoReset()),
        uploadRedemptionItemProfilePhotoAsync: (
            adminUserToken,
            fileToBeUploaded
        ) =>
            dispatch(
                actions.uploadRedemptionItemProfilePhotoAsync(
                    adminUserToken,
                    fileToBeUploaded
                )
            ),
        createRedemptionItemReset: () =>
            dispatch(actions.createRedemptionItemReset()),
        createRedemptionItemAsync: (adminUserToken, redemptionItem) =>
            dispatch(
                actions.createRedemptionItemAsync(
                    adminUserToken,
                    redemptionItem
                )
            ),
        loadSponsorsAsync: (adminUserToken) => dispatch(actions.loadSponsorsAsync(adminUserToken)),
        loadCountriesAsync: (adminUserToken) => dispatch(actions.loadCountriesAsync(adminUserToken)),
        loadProductTypesAsync: (adminUserToken) => dispatch(actions.loadProductTypesAsync(adminUserToken)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionItemCreate);
