export const range = (start, end) => {
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
};

export const adjustTimezone = (date) => {
    if (date === '' || date === undefined || date == null) {
        return null;
    } else {
        // console.log("date: ", date);
        // console.log("date.getMinutes(): ", date.getMinutes());
        // console.log("date.getTimezoneOffset(): ", date.getTimezoneOffset());
        // console.log('Final Result: ', new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset())));
        
        return new Date(
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
        );
    }
}

export const getReactAppAPIBaseUrl = () => {
  let reactAppAPIBaseUrl;
  
  try {
    // eslint-disable-next-line no-undef
    reactAppAPIBaseUrl = settings.REACT_APP_API_BASE_URL;
  } catch (error) {
    reactAppAPIBaseUrl = process.env.REACT_APP_API_BASE_URL;
  }

  return reactAppAPIBaseUrl;
}