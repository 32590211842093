import React, { useEffect } from "react";
// import * as styles2 from "./CampaignDetail.module.scss";
import * as styles from "../../assets/css/DetailStyles.module.scss";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import cn from "classnames";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";

const CampaignDetail = (props) => {

    // const [campaign, setCampaign] = useState(null);

    useEffect(() => {
        // console.log("[CampaignDetail] - useEffect");
        // console.log(props);
        // Http Requests
        props.loadCampaignAsync(props.adminUserToken, props.match.params.campaignId);
    
        // Clean-up function (optional, remove it if its not required)
        // return () => {
        //     console.log("[CampaignDetail] - Clean-up function in useEffect")
        // }
    }, []);

    if (props.campaign == null) {
        return <div className={styles.serviceBlock}>Loading...</div>;
    } else {
        return (
            <>
                <div className={styles.serviceBlock}>
                    <div className={styles.leftDiv}>
                        <h2>Campaign Management - {props.campaign.name}</h2>
                        <div className={styles.adminDate}>
                            <NavLink to={"/campaignListing"} exact>
                                &lt; back to Campaign Management List
                            </NavLink>
                        </div>
                    </div>
                    <div className={cn(styles.rightDiv, styles.edit)}>
                        <NavLink
                            to={`/campaignUpdate/${props.campaign.campaignId}`}
                            exact
                            className={styles.createNew}
                        >
                            <span>Edit Campaign</span>
                        </NavLink>
                    </div>
                    <div className={styles.clear}></div>
                </div>
                <div className={styles.horizTableBlock}>
                    <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                    >
                        <tbody>
                            <tr>
                                <td className={styles.th}># Campaign ID</td>
                                <td>
                                    {getDisplayId(
                                        props.campaign.campaignId,
                                        "# C",
                                        "0",
                                        6
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Campaign Display Picture
                                </td>
                                <td className={styles.first}>
                                    <div className={styles.thumbBig}>
                                        <img
                                            src={props.campaign.imageUrl
                                                .replace(
                                                    "{headerKey}",
                                                    "AdminUserToken"
                                                )
                                                .replace(
                                                    "{headerValue}",
                                                    props.adminUserToken
                                                )}
                                            alt=""
                                        />
                                    </div>
                                    <span className={styles.imageName}>
                                        {props.campaign.image}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Campaign Name</td>
                                <td>{props.campaign.name}</td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Campaign Description
                                </td>
                                <td className={styles.first}>
                                    {props.campaign.description}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Sponsor Name</td>
                                <td>
                                    {props.campaign.sponsor != null
                                        ? props.campaign.sponsor.name
                                        : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Country Name</td>
                                <td className={styles.first}>
                                    {props.campaign.country != null
                                        ? props.campaign.country.name
                                        : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Period (DD/MM/YYYY)
                                </td>
                                <td>
                                    <Moment format="DD/MM/YYYY">
                                        {props.campaign.startDate}
                                    </Moment>{" "}
                                    <b className={styles.space}>to</b>{" "}
                                    <Moment format="DD/MM/YYYY">
                                        {props.campaign.endDate}
                                    </Moment>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Credit Top Contributor Configuration
                                </td>
                                <td className={styles.first}>
                                    {props.campaign.creditTopContributorConfig}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>Status</td>
                                <td>
                                    <a
                                        href="#"
                                        className={
                                            props.campaign.campaignStatus
                                                .campaignStatusName === "Active"
                                                ? styles.statusActive
                                                : styles.statusExpired
                                        }
                                    >
                                        {
                                            props.campaign.campaignStatus
                                                .campaignStatusName
                                        }
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        campaign: state.campaign.selectedCampaign,
        adminUserToken: state.auth.loggedInAdminUser.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCampaignAsync: (adminUserToken, campaignId) =>
            dispatch(actions.loadCampaignAsync(adminUserToken, campaignId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail);