import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadSponsors = (sponsors) => {
    return {
        type: actionTypes.LOAD_SPONSORS,
        payload: sponsors,
    };
};

const SPONSORS_QUERY_TEMPLATE = `
query Sponsors {
  sponsors {
    sponsorId
    name
  }
}`;

const sponsorsQuery = (adminUserToken) => {
    // console.log("sponsorsQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: SPONSORS_QUERY_TEMPLATE,
    });
};

export const loadSponsorsAsync = (adminUserToken) => {
    return (dispatch) => {
        sponsorsQuery(adminUserToken).then((response) => {
            if (response.data.errors == null) {
                dispatch(loadSponsors(response.data.data.sponsors));
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                }
            }
        });
    };
};
