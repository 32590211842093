import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadMembers = (members) => {
    // console.log("loadMembers");
    // console.log(members);

    return {
        type: actionTypes.LOAD_MEMBERS,
        payload: members,
    };
};

const MEMBERS_QUERY_TEMPLATE = `
query Members {
  members(searchString: "") {
    memberId
    firstName
    lastName
    email
    countryCode
    mobileNumber
    dateOfBirth
    experienceId
    experience {
      experienceId
      experienceName
    }
    expertLevelId
    expertLevel {
      expertLevelId
      expertLevelName
    }
    credits
    lastLoginDate
  }
}`;

const membersQuery = (adminUserToken) => {
    // console.log("membersQuery");
    
    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: MEMBERS_QUERY_TEMPLATE,
    });
};

export const loadMembersAsync = (adminUserToken) => {
    return (dispatch) => {
        membersQuery(adminUserToken)
            .then(response => {
              
              if (response.data.errors == null) {
                  dispatch(loadMembers(response.data.data.members));
              } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                }
              }
            });
    };
};
