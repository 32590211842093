import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const deleteCampaignSuccess = (campaignId) => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_SUCCESS,
        payload: campaignId,
    };
};

const deleteCampaignError = (message) => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_ERROR,
        payload: message,
    };
};

export const deleteCampaignReset = () => {
    return {
        type: actionTypes.DELETE_CAMPAIGN_RESET,
    };
};

const DELETE_CAMPAIGN_MUTATION_TEMPLATE = `
mutation CampaignDelete($campaignId: Int!) {
    campaignDelete(campaignId: $campaignId)   
}`;

const deleteCampaignMutation = (adminUserToken, campaignId) => {
    // console.log("deleteCampaignMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: DELETE_CAMPAIGN_MUTATION_TEMPLATE,
        variables: { campaignId: campaignId },
    });
};

export const deleteCampaignAsync = (adminUserToken, campaignId) => {
    return (dispatch) => {
        deleteCampaignMutation(adminUserToken, campaignId).then((response) => {
            // console.log(response);
            if (response.data.errors == null) {
                dispatch(deleteCampaignSuccess(campaignId));
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                } else {
                    dispatch(deleteCampaignError(response.data.errors));
                }
            }
        });
    };
};
