import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import cn from "classnames";

import * as styles from "./Home.module.css";
import Moment from "react-moment";
import { adjustTimezone } from "../../helpers/common";

class Home extends Component {
    constructor(props) {
        // console.log("[Home] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[Home] getDerivedStateFromProps", props, state);
    // return state;
    // }

    render() {
        // console.log("[Home] render");

        return (
            <div className={styles.rightBox}>
                <div className={styles.welcomeBlock}>
                    <h1>Welcome, {this.props.loggedInUserName}.</h1>
                    <div className={styles.adminDate}>
                        <span>Today’s Date:</span>{" "}
                        <Moment format="DD-MM-YYYY">
                            {new Date()}
                        </Moment>
                    </div>
                    <div className={styles.adminDate}>
                        <span>Last Login:</span>{" "}
                        <Moment format="DD-MM-YYYY">
                            {adjustTimezone(new Date(this.props.lastLoginDate))}
                        </Moment>
                    </div>
                    <div className={styles.cl}></div>
                </div>
                <div className={styles.serviceBlock}>
                    <h2>Quick Access</h2>
                    <div className={cn(styles.service, styles.one)}>
                        <NavLink
                            to="/memberActivities"
                            exact
                            activeStyle={{}}
                            activeClassName="active"
                            data-tag=""
                            className=""
                        >
                            <span>Member Activities</span>
                        </NavLink>
                    </div>
                    <div className={cn(styles.service, styles.two)}>
                        <NavLink
                            to="/campaignListing"
                            exact
                            activeStyle={{}}
                            activeClassName="active"
                            data-tag=""
                            className=""
                        >
                            <span>Campaign Management</span>
                        </NavLink>
                    </div>
                    <div className={cn(styles.service, styles.three)}>
                        <NavLink
                            to="/redemptionItemListing"
                            exact
                            activeStyle={{}}
                            activeClassName="active"
                            data-tag=""
                            className=""
                        >
                            <span>Redemption Management</span>
                        </NavLink>
                    </div>
                    <div className={cn(styles.service, styles.four)}>
                        <NavLink
                            to="/qrScanAndRecord"
                            exact
                            activeStyle={{}}
                            activeClassName="active"
                            data-tag=""
                            className=""
                        >
                            <span>QR Scan & Record</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedInUserName: state.auth.loggedInAdminUser.adminUser.firstName,
        lastLoginDate: state.auth.loggedInAdminUser.adminUser.lastLoginDate,
    };
};

export default connect(mapStateToProps)(Home);