import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadRedemptionRequests = (redemptionRequests) => {
    // console.log("loadRedemptionRequests");
    // console.log(redemptionRequests);

    return {
        type: actionTypes.LOAD_REDEMPTION_REQUESTS,
        payload: redemptionRequests,
    };
};

const REDEMPTION_REQUESTS_QUERY = `
query RedemptionRequests($redemptionItemId: Int) {
  redemptionRequests(redemptionItemId: $redemptionItemId) {
    redemptionRequestId
    memberId
    redemptionItemId
    createdDate
    claimedDate
    member {
      firstName
      lastName
    }
    redemptionItem {
      redemptionItemId
      name
    }
  }
}`;

const redemptionRequestsQuery = (adminUserToken, redemptionItemId) => {
    // console.log("redemptionRequestsQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
      query: REDEMPTION_REQUESTS_QUERY,
      variables: { redemptionItemId }
    });
};

export const loadRedemptionRequestsAsync = (adminUserToken, redemptionItemId = null) => {
    return (dispatch) => {
        redemptionRequestsQuery(adminUserToken, redemptionItemId).then(
            (response) => {
                if (response.data.errors == null) {
                    dispatch(
                        loadRedemptionRequests(
                            response.data.data.redemptionRequests
                        )
                    );
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    }
                }
            }
        );
    };
};