export const getNoOfPages = (noOfRecords, pageSize) => {
    return Math.ceil(noOfRecords / pageSize);
}

export const getStartItemIndex = (selectedPageNumber, pageSize) => {
    return (selectedPageNumber - 1) * pageSize;
}

export const getEndItemIndex = (selectedPageNumber, pageSize) => {
    return selectedPageNumber * pageSize;
};