import React, { Component } from "react";
import * as styles from "./QRScanAndRecord.module.scss";
import { NavLink } from "react-router-dom";
import * as commonStyles from "../../assets/css/commonStyles.module.css";
import cn from "classnames";
import { connect } from "react-redux";
import Moment from "react-moment";
import Search from "../../components/UI/Search/Search";
import Pagination from "../../components/UI/Pagination/pagination";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import * as paginationHelper from "../../helpers/pagination-helper";
import * as constansts from "../../helpers/constants";
import arraySort from "array-sort";
import { adjustTimezone } from "../../helpers/common";

class QRScanAndRecord extends Component {
    constructor(props) {
        // console.log("[QRScanAndRecord] constructor");

        super(props);

        //Initialise State
        this.state = {
            searchString: "",
            sortString: "",
            reverse: false,
            pageSize: constansts.PageSize,
            selectedPageNumber: 1,
            listItemHeaders: [
                {
                    displayValue: "# Redemption ID",
                    sortBy: "redemptionRequestId",
                },
                {
                    displayValue: "Redeemed Item",
                    sortBy: "redemptionItem.name",
                },
                { displayValue: "No of Unit(s) Redeemed", sortBy: "" },
                { displayValue: "Member ID", sortBy: "memberId" },
                {
                    displayValue: "Display Name (First, Last)",
                    sortBy: "member.firstName,member.lastName",
                },
                {
                    displayValue: "Picked Up Date (DD/MM/YYYY - Time)",
                    sortBy: "claimedDate",
                },
            ],
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[QRScanAndRecord] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    pageSelectionChangeHandler = (newSelectedPageNumber) => {
        this.setState({ selectedPageNumber: newSelectedPageNumber });
    };

    previousPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber - 1 };
        });
    };

    nextPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber + 1 };
        });
    };

    filterListItems = (listItems, searchString) => {
        if (listItems != null) {
            return listItems.filter((item) => {
                return (
                    (
                        getDisplayId(item.redemptionRequestId, "# QR", "0", 6) +
                        " " +
                        getDisplayId(item.memberId, "# M", "0", 6) +
                        " " +
                        item.redemptionItem.name +
                        " " +
                        item.member.firstName +
                        " " +
                        item.member.lastName
                    )
                        .toLowerCase()
                        .indexOf(searchString.toLowerCase()) !== -1
                );
            });
        }
    };

    filterAndSortListItems = (listItems, searchString, sortString) => {
        return arraySort(
            this.filterListItems(listItems, searchString),
            sortString.split(","),
            { reverse: this.state.reverse }
        );
    };

    searchStringChangedHandler = (newSearchString) => {
        this.setState((prevState, props) => {
            const filteredListItems = this.filterListItems(
                this.props.redemptionRequests,
                newSearchString,
                prevState.sortString
            );

            const noOfPages = paginationHelper.getNoOfPages(
                filteredListItems.length,
                prevState.pageSize
            );

            return {
                searchString: newSearchString,
                selectedPageNumber: Math.min(
                    noOfPages,
                    prevState.selectedPageNumber === 0
                        ? 1
                        : prevState.selectedPageNumber
                ),
            };
        });
    };

    sortStringChangedHandler = (newSortString) => {
        // console.log("newSortString: " + newSortString);

        this.setState((prevState, props) => {
            return {
                sortString: newSortString,
                reverse:
                    prevState.sortString === newSortString
                        ? !prevState.reverse
                        : false,
            };
        });
    };

    render() {
        // console.log("[QRScanAndRecord] render");

        if (this.props.redemptionRequests == null) {
            return <div className={styles.serviceBlock}>Loading...</div>;
        }
        else {
            const filteredAndSortedListItems = this.filterAndSortListItems(
                this.props.redemptionRequests,
                this.state.searchString,
                this.state.sortString
            );
            return (
                <>
                    <div className={styles.leftCol}>
                        <div className={styles.serviceBlock}>
                            <h2>QR Scan & Record</h2>
                            <div className={styles.adminDate}>
                                <span>Total no. of Redemption: </span>{" "}
                                {this.props.redemptionRequests.length}
                            </div>
                            <div className={commonStyles.clear}></div>
                        </div>
                        <div className={styles.sortingDiv}>
                            <Search
                                searchString={this.state.searchString}
                                searchStringChanged={
                                    this.searchStringChangedHandler
                                }
                            />
                            <div className={commonStyles.clear}></div>
                        </div>
                    </div>
                    <div className={cn(styles.rightCol, styles.bigAction)}>
                        <NavLink
                            to={"/qrScan"}
                            exact
                            className={styles.qrButton}
                        >
                            <span>QR Scan & Record</span>
                        </NavLink>
                        {/* <a
                            href="qr-scan.html"
                            className={styles.qrButton}
                            data-toggle="modal"
                            data-target="#exampleModalLong"
                        >
                            <span>QR Scan & Record</span>
                        </a>{" "} */}
                    </div>
                    <div className={commonStyles.clear}></div>
                    <div className={styles.tableBlock}>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className={styles.qrTabel}
                        >
                            <tbody>
                                <tr className={styles.first}>
                                    {this.state.listItemHeaders.map(
                                        (itemHeader, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    onClick={() =>
                                                        this.sortStringChangedHandler(
                                                            itemHeader.sortBy
                                                        )
                                                    }
                                                    className={
                                                        styles.headerSort
                                                    }
                                                >
                                                    <nobr>
                                                        {itemHeader.displayValue}
                                                        <a href="#">
                                                            <i
                                                                className={
                                                                    styles.sorting
                                                                }
                                                            ></i>
                                                        </a>
                                                    </nobr>
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                                {filteredAndSortedListItems
                                    .slice(
                                        paginationHelper.getStartItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        ),
                                        paginationHelper.getEndItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        )
                                    )
                                    .map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 === 1
                                                        ? styles.light
                                                        : ""
                                                }
                                            >
                                                <td>
                                                    {getDisplayId(
                                                        item.redemptionRequestId,
                                                        "# QR",
                                                        "0",
                                                        6
                                                    )}
                                                </td>
                                                <td>
                                                    {item.redemptionItem.name}
                                                </td>
                                                <td>1</td>
                                                <td>
                                                    {getDisplayId(
                                                        item.memberId,
                                                        "# M",
                                                        "0",
                                                        6
                                                    )}
                                                </td>
                                                <td>
                                                    {`${item.member.firstName}, ${item.member.lastName}`}
                                                </td>
                                                <td>
                                                    {item.claimedDate ==
                                                    null ? (
                                                        ""
                                                    ) : (
                                                        <Moment format="DD/MM/YYYY - hh:mm">
                                                            {adjustTimezone(
                                                                new Date(
                                                                    item.claimedDate
                                                                )
                                                            )}
                                                        </Moment>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {/* Table End */}
                    <Pagination
                        selectedPageNumber={this.state.selectedPageNumber}
                        noOfItems={filteredAndSortedListItems.length}
                        pageSize={this.state.pageSize}
                        pageSelectionChanged={this.pageSelectionChangeHandler}
                        previousPageClicked={this.previousPageClickHandler}
                        nextPageClicked={this.nextPageClickHandler}
                    />
                    {/* Pagination End */}
                </>
            );
        }
    }

    componentDidMount() {
        this.props.loadRedemptionRequestsAsync(this.props.adminUserToken);
    }
}

const mapStateToProps = (state) => {
    return {
        redemptionRequests: state.redemption.redemptionRequests,
        adminUserToken: state.auth.loggedInAdminUser.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRedemptionRequestsAsync: (adminUserToken) =>
            dispatch(actions.loadRedemptionRequestsAsync(adminUserToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRScanAndRecord);