import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const createCampaignSuccess = (campaignId) => {
    return {
        type: actionTypes.CREATE_CAMPAIGN_SUCCESS,
        payload: campaignId,
    };
};

const createCampaignError = (message) => {
    return {
        type: actionTypes.CREATE_CAMPAIGN_ERROR,
        payload: message,
    };
};

export const createCampaignReset = () => {
    return {
        type: actionTypes.CREATE_CAMPAIGN_RESET,
    };
};

const CREATE_CAMPAIGN_MUTATION_TEMPLATE = `
mutation CampaignCreate($campaignDetails: CampaignInput!) {
    campaign: campaignCreate(campaignDetails: $campaignDetails) {
        campaignId
    }
}`;

const createCampaignMutation = (adminUserToken, campaign) => {
    // console.log("createCampaignMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: CREATE_CAMPAIGN_MUTATION_TEMPLATE,
        variables: { campaignDetails: campaign },
    });
};

export const createCampaignAsync = (adminUserToken, campaign) => {
    return (dispatch) => {
        createCampaignMutation(adminUserToken, campaign).then((response) => {
            // console.log(response);
            if (response.data.errors == null) {
                dispatch(
                    createCampaignSuccess(
                        response.data.data.campaign.campaignId
                    )
                );
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                } else {
                    dispatch(createCampaignError(response.data.errors));
                }
            }
        });
    };
};
