import React, { Component } from "react";
import { connect } from "react-redux";

import * as styles from "./Login.module.css";
import logoImage from "../../assets/images/logo.png";
import * as actions from '../../store/actions/actions';

class Login extends Component {
    constructor(props) {
        // console.log("[Login] constructor");

        super(props);

        //Initialise State
        this.state = {
            userName: "",
            password: ""
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[Login] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    submitHandler = event => {
        event.preventDefault();
        this.props.onLogin(this.state.userName, this.state.password);
    };

    render() {
        // console.log("[Login] render");

        return (
            <div className={styles.loginPage}>
                <div className={styles.parent}>
                    <div className={styles.innerCont}>
                        <div className={styles.logo}>
                            <img src={logoImage} alt="Logo" />
                        </div>
                        <div className={styles.clear}></div>
                        <form
                            className={styles.loginForm}
                            onSubmit={this.submitHandler}
                        >
                            <div className={styles.wrapLabel}>Login:</div>
                            <div className={styles.wrapInput}>
                                <input
                                    className={styles.input}
                                    type="text"
                                    name="username"
                                    value={this.state.userName}
                                    onChange={(event) =>
                                        this.setState({
                                            userName: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className={styles.wrapLabel}>
                                <span className={styles.textLabel}>
                                    Password:
                                </span>
                            </div>
                            <div className={styles.wrapInput}>
                                <input
                                    className={styles.input}
                                    type="password"
                                    name="pass"
                                    value={this.state.password}
                                    onChange={(event) =>
                                        this.setState({
                                            password: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className={styles.containerLoginFormBtn}>
                                <button
                                    className={styles.submit}
                                    // onClick={() =>
                                    //     this.props.onLogin(
                                    //         this.state.userName,
                                    //         this.state.password
                                    //     )
                                    // }
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (userName, password) => dispatch(actions.loginAsync({
            userName: userName,
            password: password
        }))
        
    };
};

export default connect(null, mapDispatchToProps)(Login);