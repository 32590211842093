import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import * as styles from "./RedemptionRequestListing.module.scss";
import * as commonStyles from "../../assets/css/commonStyles.module.css";

import { connect } from "react-redux";
import Moment from "react-moment";
// import Search from "../../components/UI/Search/Search";
// import Sort from "../../components/UI/Sort/Sort";
import Pagination from "../../components/UI/Pagination/pagination";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import * as paginationHelper from "../../helpers/pagination-helper";
import * as constansts from "../../helpers/constants";
import arraySort from "array-sort";
import { adjustTimezone } from "../../helpers/common";

class RedemptionRequestListing extends Component {
    constructor(props) {
        // console.log("[RedemptionRequestListing] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
            sortSelectItems: [
                {
                    key: "redemptionRequestId",
                    displayValue: "# Redemption ID",
                    sortBy: "redemptionRequestId",
                },
                {
                    key: "noOfUnitsRedeemed",
                    displayValue: "No. of Unit(s) Redeemed",
                    sortBy: "noOfUnitsRedeemed",
                },
                {
                    key: "memberId",
                    displayValue: "# Member ID",
                    sortBy: "memberId",
                },
                {
                    key: "firstName",
                    displayValue: "First Name",
                    sortBy: "member.firstName",
                },
                {
                    key: "lastName",
                    displayValue: "Last Name",
                    sortBy: "member.lastName",
                },
                {
                    key: "createdDate",
                    displayValue: "Redeemed Date (DD/MM/YYYY - Time)",
                    sortBy: "createdDate",
                },
            ],
            searchString: props.redemptionItemId.toString(),
            sortString: "",
            pageSize: constansts.PageSize,
            selectedPageNumber: 1,
            listItemHeaders: [
                "# Redemption ID",
                "No. of Unit(s) Redeemed",
                "# Member ID",
                "Display Name (First, Last)",
                "Redeemed Date (DD/MM/YYYY - Time)",
            ],
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log(
    //         "[RedemptionRequestListing] getDerivedStateFromProps",
    //         props,
    //         state
    //     );
    //     return state;
    // }

    pageSelectionChangeHandler = (newSelectedPageNumber) => {
        this.setState({ selectedPageNumber: newSelectedPageNumber });
    };

    previousPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber - 1 };
        });
    };

    nextPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber + 1 };
        });
    };

    filterListItems = (listItems, searchString) => {
        return listItems.filter((item) => {
            return (item.redemptionItemId.toString() === searchString);
        });
    };

    filterAndSortListItems = (listItems, searchString, sortString) => {
        return arraySort(
            this.filterListItems(listItems, searchString),
            sortString.split(",")
        );
    };

    searchStringChangedHandler = (newSearchString) => {
        this.setState((prevState, props) => {
            const filteredListItems = this.filterListItems(
                this.props.redemptionRequests,
                newSearchString,
                prevState.sortString
            );

            const noOfPages = paginationHelper.getNoOfPages(
                filteredListItems.length,
                prevState.pageSize
            );

            return {
                searchString: newSearchString,
                selectedPageNumber: Math.min(
                    noOfPages,
                    prevState.selectedPageNumber === 0
                        ? 1
                        : prevState.selectedPageNumber
                ),
            };
        });
    };

    sortStringChangedHandler = (newSortString) => {
        // console.log("newSortString: " + newSortString);

        this.setState((prevState, props) => {
            return { sortString: newSortString };
        });
    };

    render() {
        // console.log("[RedemptionRequestListing] render");

        if (this.props.redemptionRequests == null) {
            return <div className={styles.serviceBlock}>Loading...</div>;
        } else {
            const filteredAndSortedListItems = this.filterAndSortListItems(
                this.props.redemptionRequests,
                this.state.searchString,
                this.state.sortString
            );

            return (
                <>
                    <div className={styles.serviceBlock}>
                        <div className={styles.leftDiv}>
                            <h2>
                                {getDisplayId(
                                    this.props.redemptionItemId,
                                    "# P",
                                    "0",
                                    6
                                )}{" "}
                                - Redemption History
                            </h2>
                            <div className={styles.adminDate}>
                                <span>Total no. of Redemptions: </span>{" "}
                                {this.props.redemptionRequests.length}
                            </div>
                        </div>
                        <div className={styles.rightDiv}>
                            {" "}
                        </div>
                        <div className={commonStyles.clear}></div>
                    </div>
                    {/* <div className={styles.sortingDiv}>
                        <Search
                            searchString={this.state.searchString}
                            searchStringChanged={
                                this.searchStringChangedHandler
                            }
                        />
                        <Sort
                            selectList={this.state.sortSelectItems}
                            selectionChanged={this.sortStringChangedHandler}
                        />
                        <div className={commonStyles.clear}></div>
                    </div> */}
                    <div className={styles.tableBlock}>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className={styles.actTabel}
                        >
                            <tbody>
                                <tr className={styles.first}>
                                    {this.state.listItemHeaders.map(
                                        (itemHeader, index) => {
                                            return (
                                                <th key={index}>
                                                    <nobr>
                                                        {itemHeader}
                                                        <a href="#">
                                                            <i
                                                                className={
                                                                    styles.sorting
                                                                }
                                                            ></i>
                                                        </a>
                                                    </nobr>
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                                {filteredAndSortedListItems
                                    .slice(
                                        paginationHelper.getStartItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        ),
                                        paginationHelper.getEndItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        )
                                    )
                                    .map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 === 1
                                                        ? styles.light
                                                        : ""
                                                }
                                            >
                                                <td>
                                                    {getDisplayId(
                                                        item.redemptionRequestId,
                                                        "# QR",
                                                        "0",
                                                        6
                                                    )}
                                                </td>
                                                <td>1</td>
                                                <td>
                                                    {getDisplayId(
                                                        item.memberId,
                                                        "# M",
                                                        "0",
                                                        6
                                                    )}
                                                </td>
                                                <td>
                                                    {`${item.member.firstName}, ${item.member.lastName}`}
                                                </td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY - hh:mm">
                                                        {adjustTimezone(new Date(item.createdDate))}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {/* Table End */}
                    <Pagination
                        selectedPageNumber={this.state.selectedPageNumber}
                        noOfItems={filteredAndSortedListItems.length}
                        pageSize={this.state.pageSize}
                        pageSelectionChanged={this.pageSelectionChangeHandler}
                        previousPageClicked={this.previousPageClickHandler}
                        nextPageClicked={this.nextPageClickHandler}
                    />
                    {/* Pagination End */}
                </>
            );
        }
    }

    componentDidMount() {
        this.props.loadRedemptionRequestsAsync(this.props.adminUserToken, this.props.redemptionItemId);
    }
}

const mapStateToProps = (state) => {
    return {
        redemptionRequests: state.redemption.redemptionRequests,
        adminUserToken: state.auth.loggedInAdminUser.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRedemptionRequestsAsync: (adminUserToken, redemptionItemId) =>
            dispatch(
                actions.loadRedemptionRequestsAsync(
                    adminUserToken,
                    redemptionItemId
                )
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionRequestListing);
