import axios from 'axios';
import { getReactAppAPIBaseUrl } from "../helpers/common";

const axiosInstance = axios.create({
    baseURL: getReactAppAPIBaseUrl(),
    headers: {
        // Authorization: `bearer AUTH_TOKEN_FROM_INSTANCE`,
        // ContentType: `application/json`,
    },
});

axiosInstance.interceptors.request.use(requestConfiguration => {
    // console.log(requestConfiguration);

    // Edit requestConfiguration (if required) here, eg., add TOKEN etc
    return requestConfiguration;
}, error => {
    console.error(error);
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    response => {
        // console.log(response);

        // Edit response
        return response;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);

export default axiosInstance;

/*
-------------------------------------------------------------------------------------------
How to use axiosInstance
-------------------------------------------------------------------------------------------
import axios from "path to axiosInstance goes here";

axios.get("url", {optional configObject}).then(response => console.log(response)).catch(error => console.error(error))
axios.post("url", data, {optional configObject}).then(response => console.log(response)).catch(error => console.error(error))
axios.delete("url", {optional configObject}).then(response => console.log(response)).catch(error => console.error(error))
*/