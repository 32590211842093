import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as styles from "./RedemptionItemListing.module.css";
import * as commonStyles from "../../assets/css/commonStyles.module.css";

import { connect } from "react-redux";
import Moment from "react-moment";
import Search from "../../components/UI/Search/Search";
import Sort from "../../components/UI/Sort/Sort";
import Pagination from "../../components/UI/Pagination/pagination";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import * as paginationHelper from "../../helpers/pagination-helper";
import * as constansts from "../../helpers/constants";
import arraySort from "array-sort";

class RedemptionItemListing extends Component {
    constructor(props) {
        // console.log("[RedemptionItemListing] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
            sortSelectItems: [
                {
                    key: "redemptionItemId",
                    displayValue: "# Promotion ID",
                    sortBy: "redemptionItemId",
                },
                {
                    key: "image",
                    displayValue: "Promotion Display Picture",
                    sortBy: "image",
                },
                {
                    key: "name",
                    displayValue: "Promotion Name",
                    sortBy: "name",
                },
                {
                    key: "startDate",
                    displayValue: "Start Date",
                    sortBy: "startDate",
                },
                {
                    key: "endDate",
                    displayValue: "End Date",
                    sortBy: "endDate",
                },
                {
                    key: "points",
                    displayValue: "V Points",
                    sortBy: "points",
                },
                {
                    key: "availableStock",
                    displayValue: "No. of Stock",
                    sortBy: "availableStock",
                },
                {
                    key: "redemptionItemStatus",
                    displayValue: "Status",
                    sortBy: "redemptionItemStatus.redemptionItemStatusName",
                },
            ],
            searchString: "",
            sortString: "",
            reverse: false,
            pageSize: constansts.PageSize,
            selectedPageNumber: 1,
            listItemHeaders: [
                { displayValue: "# Promotion ID", sortBy: "redemptionItemId" },
                { displayValue: "Promotion Display Picture", sortBy: "image" },
                { displayValue: "Promotion Name", sortBy: "name" },
                {
                    displayValue: "Start Date (DD/MM/YYYY)",
                    sortBy: "startDate",
                },
                { displayValue: "End Date (DD/MM/YYYY)", sortBy: "endDate" },
                { displayValue: "V Points Value", sortBy: "points" },
                { displayValue: "No. of Stock", sortBy: "availableStock" },
                {
                    displayValue: "Status",
                    sortBy: "redemptionItemStatus.redemptionItemStatusName",
                },
            ],
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log(
    //         "[RedemptionItemListing] getDerivedStateFromProps",
    //         props,
    //         state
    //     );
    //     return state;
    // }

    pageSelectionChangeHandler = (newSelectedPageNumber) => {
        this.setState({ selectedPageNumber: newSelectedPageNumber });
    };

    previousPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber - 1 };
        });
    };

    nextPageClickHandler = () => {
        this.setState((prevState, props) => {
            return { selectedPageNumber: prevState.selectedPageNumber + 1 };
        });
    };

    filterListItems = (listItems, searchString) => {
        return listItems.filter((item) => {
            return (
                item.name.toLowerCase().indexOf(searchString.toLowerCase()) !==
                -1
            );
        });
    };

    filterAndSortListItems = (listItems, searchString, sortString) => {
        return arraySort(
            this.filterListItems(listItems, searchString),
            sortString.split(","),
            { reverse: this.state.reverse }
        );
    };

    searchStringChangedHandler = (newSearchString) => {
        this.setState((prevState, props) => {
            const filteredListItems = this.filterListItems(
                this.props.redemptionItems,
                newSearchString,
                prevState.sortString
            );

            const noOfPages = paginationHelper.getNoOfPages(
                filteredListItems.length,
                prevState.pageSize
            );

            return {
                searchString: newSearchString,
                selectedPageNumber: Math.min(
                    noOfPages,
                    prevState.selectedPageNumber === 0 ? 1 : prevState.selectedPageNumber
                ),
            };
        });
    };

    sortStringChangedHandler = (newSortString) => {
        // console.log("newSortString: " + newSortString);

        this.setState((prevState, props) => {
            return {
                sortString: newSortString,
                reverse:
                    prevState.sortString === newSortString
                        ? !prevState.reverse
                        : false,
            };
        });
    };

    render() {
        // console.log("[RedemptionItemListing] render");

        if (this.props.redemptionItems == null) {
            return <div className={styles.serviceBlock}>Loading...</div>;
        }
        else {
            const filteredAndSortedListItems = this.filterAndSortListItems(
                this.props.redemptionItems,
                this.state.searchString,
                this.state.sortString
            );

            return (
                <>
                    <div className={styles.serviceBlock}>
                        <div className={styles.leftDiv}>
                            <h2>Redemption Management</h2>
                            <div className={styles.adminDate}>
                                <span>Total no. of Promotions: </span>{" "}
                                {this.props.redemptionItems.length}
                            </div>
                        </div>
                        <div className={styles.rightDiv}>
                            <NavLink
                                to={"/qrScan"}
                                exact
                                className={styles.qrButton}
                            >
                                <span>Scan QR Code</span>
                            </NavLink>
                            <NavLink
                                to={"/redemptionItemCreate"}
                                exact
                                className={styles.createNew}
                            >
                                <span>Create New</span>
                            </NavLink>
                        </div>
                        <div className={commonStyles.clear}></div>
                    </div>
                    <div className={styles.sortingDiv}>
                        <Search
                            searchString={this.state.searchString}
                            searchStringChanged={
                                this.searchStringChangedHandler
                            }
                        />
                        <Sort
                            selectList={this.state.sortSelectItems}
                            selectionChanged={this.sortStringChangedHandler}
                        />
                        <div className={commonStyles.clear}></div>
                    </div>
                    <div className={styles.tableBlock}>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className={styles.actTabel}
                        >
                            <tbody>
                                <tr className={styles.first}>
                                    {this.state.listItemHeaders.map(
                                        (itemHeader, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    onClick={() =>
                                                        this.sortStringChangedHandler(
                                                            itemHeader.sortBy
                                                        )
                                                    }
                                                    className={
                                                        styles.headerSort
                                                    }
                                                >
                                                    <nobr>
                                                        {
                                                            itemHeader.displayValue
                                                        }
                                                        <a href="#">
                                                            <i
                                                                className={
                                                                    styles.sorting
                                                                }
                                                            ></i>
                                                        </a>
                                                    </nobr>
                                                </th>
                                            );
                                        }
                                    )}
                                </tr>
                                {filteredAndSortedListItems
                                    .slice(
                                        paginationHelper.getStartItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        ),
                                        paginationHelper.getEndItemIndex(
                                            this.state.selectedPageNumber,
                                            this.state.pageSize
                                        )
                                    )
                                    .map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 === 1
                                                        ? styles.light
                                                        : ""
                                                }
                                            >
                                                <td>
                                                    <NavLink
                                                        to={`/redemptionItemDetail/${item.redemptionItemId}`}
                                                    >
                                                        {getDisplayId(
                                                            item.redemptionItemId,
                                                            "# P",
                                                            "0",
                                                            6
                                                        )}
                                                    </NavLink>
                                                </td>
                                                <td>
                                                    <div
                                                        className={styles.thumb}
                                                    >
                                                        <NavLink
                                                            to={`/redemptionItemDetail/${item.redemptionItemId}`}
                                                        >
                                                            <img
                                                                src={item.imageUrl
                                                                    .replace(
                                                                        "{headerKey}",
                                                                        "AdminUserToken"
                                                                    )
                                                                    .replace(
                                                                        "{headerValue}",
                                                                        this
                                                                            .props
                                                                            .adminUserToken
                                                                    )}
                                                                alt=""
                                                            />
                                                        </NavLink>
                                                        {/* <a href="redemption-management-detail.html">
                                                            <img
                                                                src={item.imageUrl
                                                                    .replace(
                                                                        "{headerKey}",
                                                                        "AdminUserToken"
                                                                    )
                                                                    .replace(
                                                                        "{headerValue}",
                                                                        this
                                                                            .props
                                                                            .adminUserToken
                                                                    )}
                                                                alt=""
                                                            />
                                                        </a> */}
                                                    </div>
                                                    <span
                                                        className={
                                                            styles.imageName
                                                        }
                                                    >
                                                        {item.image}
                                                    </span>
                                                </td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.startDate}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.endDate}
                                                    </Moment>
                                                </td>
                                                <td>{item.points}</td>
                                                <td>{item.availableStock}</td>
                                                <td>
                                                    <a
                                                        href="#"
                                                        className={
                                                            item
                                                                .redemptionItemStatus
                                                                .redemptionItemStatusName ===
                                                            "Active"
                                                                ? styles.statusActive
                                                                : styles.statusExpired
                                                        }
                                                    >
                                                        {
                                                            item
                                                                .redemptionItemStatus
                                                                .redemptionItemStatusName
                                                        }
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {/* Table End */}
                    <Pagination
                        selectedPageNumber={this.state.selectedPageNumber}
                        noOfItems={filteredAndSortedListItems.length}
                        pageSize={this.state.pageSize}
                        pageSelectionChanged={this.pageSelectionChangeHandler}
                        previousPageClicked={this.previousPageClickHandler}
                        nextPageClicked={this.nextPageClickHandler}
                    />
                    {/* Pagination End */}
                </>
            );
        }
    }

    componentDidMount() {
        this.props.loadRedemptionItemsAsync(this.props.adminUserToken);
    }
}

const mapStateToProps = (state) => {
    return {
        redemptionItems: state.redemption.redemptionItems,
        adminUserToken: state.auth.loggedInAdminUser.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadRedemptionItemsAsync: (adminUserToken) =>
            dispatch(actions.loadRedemptionItemsAsync(adminUserToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionItemListing);