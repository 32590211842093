import React from "react";
import * as styles from "./Search.module.css";

const Search = (props) => {

    // useEffect(() => {
    //     console.log("[Search] - useEffect");
    //     // Http Requests
    
    //     // Clean-up function (optional, remove it if its not required)
    //     return () => {
    //         console.log("[Search] - Clean-up function in useEffect")
    //     }
    // }, [Add dependencies here OR keep it empty array to run the effect only once OR remove array to run the effect with every render cycle]);

    return (
        <div className={styles.searchDiv}>
            <input
                name=""
                type="text"
                value={props.searchString}
                onChange={(event) => props.searchStringChanged(event.target.value)}
                placeholder="Search"
                className={styles.searchInput}
            />
            <input
                type="submit"
                value="Search"
                className={styles.send}
            />
        </div>
    );
}

export default Search;