import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadRedemptionItems = (redemptionItems) => {
    // console.log("loadRedemptionItems");
    // console.log(redemptionItems);

    return {
        type: actionTypes.LOAD_REDEMPTION_ITEMS,
        payload: redemptionItems,
    };
};

const REDEMPTION_ITEMS_QUERY_TEMPLATE = `
query RedemptionItems {
  redemptionItems {
    redemptionItemId
    name
    description
    termsAndConditions
    supplierName
    image
    imageUrl
    startDate
    endDate
    expiryDate
    points
    availableStock
    redemptionItemStatusId
    redemptionItemStatus {
      redemptionItemStatusName
    }
  }
}`;

const redemptionItemsQuery = (adminUserToken) => {
    // console.log("redemptionItemsQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: REDEMPTION_ITEMS_QUERY_TEMPLATE,
    });
};

export const loadRedemptionItemsAsync = (adminUserToken) => {
    return (dispatch) => {
        redemptionItemsQuery(adminUserToken).then((response) => {
            
            if (response.data.errors == null) {
                dispatch(
                    loadRedemptionItems(response.data.data.redemptionItems)
                );
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                }
            }
        });
    };
};
