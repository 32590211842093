import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/actions";
import * as styles from "./LeftNavigation.module.css";

class LeftNavigation extends Component {
    constructor(props) {
        // console.log("[LeftNavigation] constructor");

        super(props);

        //Initialise State
        this.state = {
            //tempState: 'Add state here'
        };
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("[LeftNavigation] getDerivedStateFromProps", props, state);
    //     return state;
    // }

    logoutHandler = (event) => {
        event.preventDefault();
        this.props.logout(this.props.adminUserToken);
    }

    render() {
      // console.log("[LeftNavigation] render");

        return (
            <div className={styles.menu}>
                <nav>
                    <ul>
                        <li>
                            <NavLink
                                to="/home"
                                exact
                                activeStyle={{}}
                                activeClassName="active"
                                data-tag="one"
                                className={styles.one}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/memberActivities"
                                exact
                                activeStyle={{}}
                                activeClassName="active"
                                data-tag="two"
                                className={styles.two}
                            >
                                Member Activities
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/campaignListing"
                                exact
                                activeStyle={{}}
                                activeClassName="active"
                                data-tag="three"
                                className={styles.three}
                            >
                                Campaign Management
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink
                                        to="/campaignListing"
                                        exact
                                        activeStyle={{}}
                                        activeClassName="active"
                                    >
                                        &nbsp;&gt;&nbsp;List
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/campaignCreate"
                                        exact
                                        activeStyle={{}}
                                        activeClassName="active"
                                    >
                                        &nbsp;&gt;&nbsp;Create New Campaign
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink
                                to="/redemptionItemListing"
                                exact
                                activeStyle={{}}
                                activeClassName="active"
                                data-tag="four"
                                className={styles.four}
                            >
                                Redemption Management
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink
                                        to="/redemptionItemListing"
                                        exact
                                        activeStyle={{}}
                                        activeClassName="active"
                                    >
                                        &nbsp;&gt;&nbsp;List
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/redemptionItemCreate"
                                        exact
                                        activeStyle={{}}
                                        activeClassName="active"
                                    >
                                        &nbsp;&gt;&nbsp;Create New Promotion
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink
                                to="/qrScanAndRecord"
                                exact
                                activeStyle={{}}
                                activeClassName="active"
                                data-tag="five"
                                className={styles.five}
                            >
                                QR Scan and Record
                            </NavLink>
                        </li>
                    </ul>
                    <div className={styles.logout}>
                        <a href="#" onClick={(event) => this.logoutHandler(event)}>
                            Logout
                        </a>
                    </div>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (adminUserToken) =>
            dispatch(actions.logoutAsync(adminUserToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);
