import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./assets/css/font-awesome.min.css";

import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import campaignReducer from "./store/reducers/campaignReducer";
import authReducer from "./store/reducers/authReducer";
import memberReducer from "./store/reducers/memberReducer";
import redemptionReducer from "./store/reducers/redemptionReducer";
import lookupReducer from "./store/reducers/lookupReducer";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

// Alert optional configuration
const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '5px',
    transition: transitions.SLIDE,
};

const rootReducer = combineReducers({
    auth: authReducer,
    campaign: campaignReducer,
    member: memberReducer,
    redemption: redemptionReducer,
    lookup: lookupReducer,
});

const logger = store => {
    return next => {
        return action => {
            // console.log('[Middleware] Dispatching', action);
            const result = next(action);
            // console.log('[Middleware] next state', store.getState())
            return result;
        }
    }
}

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(logger, thunk))
);

ReactDOM.render(
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
            <App />
        </AlertProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
