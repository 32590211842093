import { updateObject } from "../utility";
import * as actionTypes from "../actions/actionTypes";
import { SUCCESS, ERROR } from "../../helpers/constants";

const initialState = {
    uploadRedemptionItemProfileImage: "",
    // Create
    createRedemptionItemStatus: null,
    createRedemptionItemId: null,
    createRedemptionItemError: null,
    // Update
    updateRedemptionItemStatus: null,
    updateRedemptionItemId: null,
    updateRedemptionItemError: null,
    // Claim
    claimRedemptionRequestStatus: null,
    claimRedemptionRequestId: null,
    claimRedemptionRequestError: null,
    // Delete
    deleteRedemptionItemId: null,
    deleteRedemptionItemStatus: null,
    deleteRedemptionItemError: null,
};

const redemptionReducer = (state = initialState, action) => {
    let updatedStateSlice = {};

    switch (action.type) {
        case actionTypes.LOAD_REDEMPTION_REQUESTS:
            updatedStateSlice = {
                redemptionRequests: action.payload,
            };
            break;
        case actionTypes.LOAD_REDEMPTION_ITEM:
            updatedStateSlice = {
                selectedRedemptionItem: action.payload,
            };
            break;
        case actionTypes.LOAD_REDEMPTION_ITEMS:
            updatedStateSlice = {
                redemptionItems: action.payload,
            };
            break;
        case actionTypes.UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_RESET:
            updatedStateSlice = {
                uploadRedemptionItemProfileImage: "",
            };
            break;
        case actionTypes.UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_SUCCESS:
            updatedStateSlice = {
                uploadRedemptionItemProfileImage: action.payload,
            };
            break;
        case actionTypes.CREATE_REDEMPTION_ITEM_SUCCESS:
            updatedStateSlice = {
                createRedemptionItemStatus: SUCCESS,
                createRedemptionItemId: action.payload,
            };
            break;
        case actionTypes.CREATE_REDEMPTION_ITEM_ERROR:
            updatedStateSlice = {
                createRedemptionItemStatus: ERROR,
                createRedemptionItemError: action.payload,
            };
            break;
        case actionTypes.CREATE_REDEMPTION_ITEM_RESET:
            updatedStateSlice = {
                createRedemptionItemStatus: null,
                createRedemptionItemId: null,
                createRedemptionItemError: null,
            };
            break;
        case actionTypes.UPDATE_REDEMPTION_ITEM_SUCCESS:
            updatedStateSlice = {
                updateRedemptionItemStatus: SUCCESS,
                updateRedemptionItemId: action.payload,
            };
            break;
        case actionTypes.UPDATE_REDEMPTION_ITEM_ERROR:
            updatedStateSlice = {
                updateRedemptionItemStatus: ERROR,
                updateRedemptionItemError: action.payload,
            };
            break;
        case actionTypes.UPDATE_REDEMPTION_ITEM_RESET:
            updatedStateSlice = {
                updateRedemptionItemStatus: null,
                updateRedemptionItemId: null,
                updateRedemptionItemError: null,
            };
            break;
        case actionTypes.CLAIM_REDEMPTION_REQUEST_SUCCESS:
            updatedStateSlice = {
                claimRedemptionRequestStatus: SUCCESS,
                claimRedemptionRequestId: action.payload,
            };
            break;
        case actionTypes.CLAIM_REDEMPTION_REQUEST_ERROR:
            updatedStateSlice = {
                claimRedemptionRequestStatus: ERROR,
                claimRedemptionRequestError: action.payload,
            };
            break;
        case actionTypes.CLAIM_REDEMPTION_REQUEST_RESET:
            updatedStateSlice = {
                claimRedemptionRequestStatus: null,
                claimRedemptionRequestId: null,
                claimRedemptionRequestError: null,
            };
            break;
        case actionTypes.DELETE_REDEMPTION_ITEM_SUCCESS:
            updatedStateSlice = {
                deleteRedemptionItemStatus: SUCCESS,
                deleteRedemptionItemId: action.payload,
            };
            break;
        case actionTypes.DELETE_REDEMPTION_ITEM_ERROR:
            updatedStateSlice = {
                deleteRedemptionItemStatus: ERROR,
                deleteRedemptionItemError: action.payload,
            };
            break;
        case actionTypes.DELETE_REDEMPTION_ITEM_RESET:
            updatedStateSlice = {
                deleteRedemptionItemId: null,
                deleteRedemptionItemStatus: null,
                deleteRedemptionItemError: null,
            };
            break;
        default:
    }

    return updateObject(state, updatedStateSlice);
};

export default redemptionReducer;
