import React, { useEffect } from "react";
// import * as styles2 from "./CampaignCreate.module.scss";
import * as styles from "../../assets/css/DetailStyles.module.scss";
import { NavLink } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import cn from "classnames";
import * as actions from "../../store/actions/actions";
import { getDisplayId } from "../../helpers/entity-helper";
import { useForm, Controller } from "react-hook-form";
import NoImage from "../../assets/images/no-image.png";
import { ERROR_BACKGROUND_COLOR, SUCCESS, ERROR } from "../../helpers/constants";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { getReactAppAPIBaseUrl } from "../../helpers/common";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const CampaignCreate = (props) => {
    const alert = useAlert();
    
    const createCampaignId = props.createCampaignId;
    const createCampaignStatus = props.createCampaignStatus;
    const createCampaignError = props.createCampaignError;
    const uploadCampaignProfilePhotoReset =
        props.uploadCampaignProfilePhotoReset;
    const createCampaignReset = props.createCampaignReset;
    const defaultSponsorOption = { value: null, label: "Select a Sponsor" };
    const defaultCountryOption = { value: null, label: "Select a Country" };
  
    const {
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        control,
        errors,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
    });

    const validateDates = () => {
        const startDate = getValues("startDate");
        const endDate = getValues("endDate");
        if (!!startDate && !!endDate) {
            if (new Date(startDate) > new Date(endDate)) {
                setError("startDate", {
                    type: "manual",
                    message: "Start Date should be less than End Date",
                });
                setError("endDate", {
                    type: "manual",
                    message: "Start Date should be less than End Date",
                });
                return false;
            } else {
                clearErrors(["startDate", "endDate"]);
                return true;
            }
        } else {
            return true;
        }
    }

    const fileChangeHandler = (event) => {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        props.uploadCampaignProfilePhotoAsync(props.adminUserToken, data);
    };

    const onSubmit = (data) => {
        // console.log("----- FORM DATA -----");
        // console.log(data);
        props.createCampaignAsync(props.adminUserToken, data);
    };

    const onReset = () => {
        reset({
            startDate: null,
            endDate: null,
            sponsorId: null,
            countryId: null,
        });
        props.uploadCampaignProfilePhotoReset();
    }

    const getSelectedSponsorOption = (sponsorId) => {
        const selectedSponsor = props.sponsors.find(
            (sponsor) => sponsor.sponsorId === sponsorId
        );
        if (selectedSponsor) {
            return {
                value: selectedSponsor.sponsorId,
                label: selectedSponsor.name,
            };
        } else {
            return defaultSponsorOption;
        }
    };

    const getSelectedCountryOption = (countryId) => {
        const selectedCountry = props.countries.find(
            (country) => country.countryId === countryId
        );
        if (selectedCountry) {
            return {
                value: selectedCountry.countryId,
                label: selectedCountry.name,
            };
        } else {
            return defaultCountryOption;
        }
    };

    useEffect(() => {
        if (props.sponsors == null) {
            props.loadSponsorsAsync(props.adminUserToken);
        }
        if (props.countries == null) {
            props.loadCountriesAsync(props.adminUserToken);
        }
    }, []);

    useEffect(() => {
        // console.log("[CampaignCreate] - useEffect");

        // Clean-up function (optional, remove it if its not required)
        return () => {
            // console.log("[CampaignCreate] - Clean-up function in useEffect");
            uploadCampaignProfilePhotoReset();
        };
    }, [uploadCampaignProfilePhotoReset]);

    let history = useHistory();

    useEffect(() => {
        if (createCampaignStatus === SUCCESS && createCampaignId != null) {
            alert.success(
                `${getDisplayId(
                    createCampaignId,
                    "# C",
                    "0",
                    6
                )} created`
            );

            history.push(`/campaignDetail/${createCampaignId}`);
        } else if (createCampaignStatus === ERROR) {
            console.error(createCampaignError.map((x) => x.message).join(", "));
            alert.error('Error Occured');
        }

        return () => {
            // console.log("[CampaignCreate] - Clean-up function in useEffect");
            createCampaignReset();
        };
    }, [
        createCampaignId,
        createCampaignStatus,
        createCampaignError,
        createCampaignReset,
    ]);

    var backgroundImageStyle;
    if (
        props.uploadCampaignProfileImage == null ||
        props.uploadCampaignProfileImage === ""
    ) {
        backgroundImageStyle = {
            backgroundImage: `url(${NoImage})`,
        };
    } else {
        backgroundImageStyle = {
            backgroundImage: `url(${getReactAppAPIBaseUrl()}api/image/getCampaignProfilePhoto?fileName=${
                props.uploadCampaignProfileImage
            }&headerKey=adminUserToken&headerValue=${props.adminUserToken})`,
        };
    }
    return (
        <>
            <div className={styles.serviceBlock}>
                <div className={styles.leftDiv}>
                    <h2>Campaign Management</h2>
                    <div className={styles.adminDate}>
                        <NavLink to={"/campaignListing"} exact>
                            &lt; back to Campaign Management List
                        </NavLink>
                    </div>
                </div>
                <div className={styles.clear}></div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.horizTableBlock}>
                    <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                        className={styles.actTabel}
                    >
                        <tbody>
                            <tr>
                                <td className={styles.th}>
                                    Campaign Display Picture<sup>*</sup>
                                </td>
                                <td className={styles.first}>
                                    <div
                                        className={styles.fileUpload}
                                        style={
                                            errors.image
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    >
                                        <div
                                            className={styles.imageUploadWrap}
                                            style={backgroundImageStyle}
                                        >
                                            <div className={styles.dragText}>
                                                <h3>&nbsp;</h3>
                                            </div>
                                            <input
                                                name="image"
                                                ref={register({
                                                    required: true,
                                                })}
                                                type="hidden"
                                                value={
                                                    props.uploadCampaignProfileImage
                                                }
                                            />
                                            <input
                                                name="file"
                                                className={
                                                    styles.fileUploadInput
                                                }
                                                type="file"
                                                onChange={fileChangeHandler}
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Campaign Name<sup>*</sup>
                                </td>
                                <td>
                                    <input
                                        name="name"
                                        ref={register({ required: true })}
                                        type="text"
                                        // placeholder="Campaign Name"
                                        className={styles.input}
                                        style={
                                            errors.name
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Campaign Description<sup>*</sup>
                                    <br />
                                    (Max. 100 words)
                                </td>
                                <td
                                    className={cn(
                                        styles.first,
                                        styles.heightBig
                                    )}
                                >
                                    <textarea
                                        name="description"
                                        ref={register({ required: true })}
                                        // placeholder="Description"
                                        className={styles.textarea}
                                        style={
                                            errors.description
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    ></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Sponsor Name<sup>*</sup>
                                </td>
                                <td>
                                    {props.sponsors && (
                                        <Controller
                                            control={control}
                                            name="sponsorId"
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <Dropdown
                                                    value={getSelectedSponsorOption(
                                                        value
                                                    )}
                                                    options={[
                                                        defaultSponsorOption,
                                                    ].concat(
                                                        props.sponsors.map(
                                                            (sponsor) => {
                                                                return {
                                                                    value: sponsor.sponsorId,
                                                                    label: sponsor.name,
                                                                };
                                                            }
                                                        )
                                                    )}
                                                    controlClassName={
                                                        errors.sponsorId
                                                            ? styles.error
                                                            : ""
                                                    }
                                                    onChange={(
                                                        selectedSponsor
                                                    ) =>
                                                        onChange(
                                                            (value =
                                                                selectedSponsor.value)
                                                        )
                                                    }
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Country Name<sup>*</sup>
                                </td>
                                <td className={styles.first}>
                                    {props.countries && (
                                        <Controller
                                            control={control}
                                            name="countryId"
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) =>
                                                props.countries && (
                                                    <Dropdown
                                                        value={getSelectedCountryOption(
                                                            value
                                                        )}
                                                        options={[
                                                            defaultCountryOption,
                                                        ].concat(
                                                            props.countries.map(
                                                                (country) => {
                                                                    return {
                                                                        value: country.countryId,
                                                                        label: country.name,
                                                                    };
                                                                }
                                                            )
                                                        )}
                                                        controlClassName={
                                                            errors.countryId
                                                                ? styles.error
                                                                : ""
                                                        }
                                                        onChange={(
                                                            selectedCountry
                                                        ) =>
                                                            onChange(
                                                                (value =
                                                                    selectedCountry.value)
                                                            )
                                                        }
                                                        onBlur={onBlur}
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Period (DD/MM/YYYY)<sup>*</sup>
                                </td>
                                <td>
                                    <div className={styles.calenderWrapper}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                                validate: validateDates,
                                            }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(value) => {
                                                        onChange(value);
                                                    }}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    className={
                                                        errors.startDate
                                                            ? styles.calenderError
                                                            : styles.calender
                                                    }
                                                    wrapperClassName={
                                                        styles.calenderPopper01
                                                    }
                                                    placeholderText="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                        <b className={styles.spaceTo}>to</b>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                                validate: validateDates,
                                            }}
                                            render={({
                                                onChange,
                                                onBlur,
                                                value,
                                            }) => (
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(value) => {
                                                        onChange(value);
                                                    }}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    className={
                                                        errors.endDate
                                                            ? styles.calenderError
                                                            : styles.calender
                                                    }
                                                    wrapperClassName={
                                                        styles.calenderPopper02
                                                    }
                                                    placeholderText="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Credit Top Contributor Configuration
                                </td>
                                <td className={styles.first}>
                                    <input
                                        name="creditTopContributorConfig"
                                        ref={register({ required: false })}
                                        type="text"
                                        placeholder="Comma separated values eg., 100,80,60"
                                        className={styles.input}
                                        style={
                                            errors.creditTopContributorConfig
                                                ? {
                                                      backgroundColor:
                                                          ERROR_BACKGROUND_COLOR,
                                                  }
                                                : {}
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.th}>
                                    Status<sup>*</sup>
                                </td>
                                <td>
                                    <label className={styles.radioCont}>
                                        Active
                                        <input
                                            name="campaignStatusId"
                                            ref={register({ required: true })}
                                            style={
                                                errors.campaignStatusId
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                            type="radio"
                                            defaultChecked
                                            value="1"
                                        />
                                        <span
                                            className={styles.checkmark}
                                        ></span>
                                    </label>
                                    <label className={styles.radioCont}>
                                        Expired
                                        <input
                                            name="campaignStatusId"
                                            ref={register({ required: true })}
                                            style={
                                                errors.campaignStatusId
                                                    ? {
                                                          backgroundColor:
                                                              ERROR_BACKGROUND_COLOR,
                                                      }
                                                    : {}
                                            }
                                            type="radio"
                                            value="2"
                                        />
                                        <span
                                            className={styles.checkmark}
                                        ></span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Table End */}
                <div className={cn(styles.paginationBlock, styles.create)}>
                    <div className={styles.back}>
                        <input
                            type="button"
                            value="Cancel"
                            onClick={onReset}
                        ></input>
                    </div>
                    <div className={styles.next}>
                        <input type="submit" value="Save Changes" />
                    </div>
                    <div className={styles.clearfix}></div>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        adminUserToken: state.auth.loggedInAdminUser.token,
        uploadCampaignProfileImage: state.campaign.uploadCampaignProfileImage,
        createCampaignId: state.campaign.createCampaignId,
        createCampaignStatus: state.campaign.createCampaignStatus,
        createCampaignError: state.campaign.createCampaignError,
        sponsors: state.lookup.sponsors,
        countries: state.lookup.countries
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadCampaignProfilePhotoReset: () => dispatch(actions.uploadCampaignProfilePhotoReset()),
        uploadCampaignProfilePhotoAsync: (adminUserToken, fileToBeUploaded) =>
            dispatch(
                actions.uploadCampaignProfilePhotoAsync(
                    adminUserToken,
                    fileToBeUploaded
                )
            ),
        createCampaignReset: () => dispatch(actions.createCampaignReset()),
        createCampaignAsync: (adminUserToken, campaign) =>
            dispatch(actions.createCampaignAsync(adminUserToken, campaign)),
        loadSponsorsAsync: (adminUserToken) => dispatch(actions.loadSponsorsAsync(adminUserToken)),
        loadCountriesAsync: (adminUserToken) => dispatch(actions.loadCountriesAsync(adminUserToken))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreate);
