import React from "react";
import * as styles from "./Pagination.module.css";

import { getNoOfPages } from "../../../helpers/pagination-helper";
import { range } from "../../../helpers/common";

const Pagination = (props) => {
    
    // useEffect(() => {
    //     console.log("[Pagination] - useEffect");
    //     // Http Requests
    
    //     // Clean-up function (optional, remove it if its not required)
    //     return () => {
    //         console.log("[Pagination] - Clean-up function in useEffect")
    //     }
    // }, [Add dependencies here OR keep it empty array to run the effect only once OR remove array to run the effect with every render cycle]);

    const noOfPages = getNoOfPages(props.noOfItems, props.pageSize);
    const pageNumbers = range(1, noOfPages);

    return (
        <>
            <div className={styles.showingResult}>
                Showing results {props.selectedPageNumber} of {noOfPages} (
                {props.noOfItems} items)
            </div>
            <div className={styles.paginationBlock}>
                <div className={styles.back}>
                    <a
                        disabled={props.selectedPageNumber === 1}
                        className={
                            props.selectedPageNumber > 1 ? styles.active : ""
                        }
                        href="#"
                        onClick={props.previousPageClicked}
                    >
                        &lt;&nbsp;Previous
                    </a>
                </div>
                <div className={styles.numberPage}>
                    <ul>
                        {pageNumbers.map((pageNumber, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        pageNumber === props.selectedPageNumber
                                            ? styles.active
                                            : ""
                                    }
                                >
                                    <a
                                        href="#"
                                        onClick={() =>
                                            props.pageSelectionChanged(
                                                pageNumber
                                            )
                                        }
                                    >
                                        {pageNumber}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={styles.next}>
                    <a
                        disabled={props.selectedPageNumber >= noOfPages}
                        className={
                            props.selectedPageNumber < noOfPages
                                ? styles.active
                                : ""
                        }
                        href="#"
                        onClick={props.nextPageClicked}
                    >
                        Next&nbsp;&gt;
                    </a>
                </div>
                <div className={styles.clearfix}></div>
            </div>
        </>
    );
}

export default Pagination;