import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadRedemptionItem = (redemptionItem) => {
    return {
        type: actionTypes.LOAD_REDEMPTION_ITEM,
        payload: redemptionItem,
    };
};

const REDEMPTION_ITEM_QUERY_TEMPLATE = `
query RedemptionItem (
    $redemptionItemId: Int!
){
  redemptionItem(redemptionItemId: $redemptionItemId) {
    redemptionItemId
    name
    description
    termsAndConditions
    supplierName
    image
    imageUrl
    startDate
    endDate
    expiryDate
    points
    availableStock
    redemptionItemStatusId
    redemptionItemStatus {
      redemptionItemStatusName
    }
    sponsorId
    sponsor {
      sponsorId
      name
    }
    countryId
    country {
      countryId
      name
    }
    productTypeId
    productType {
      productTypeId
      name
    }
  }
}`;

const redemptionItemQuery = (adminUserToken, redemptionItemId) => {
    // console.log("redemptionItemQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: REDEMPTION_ITEM_QUERY_TEMPLATE,
        variables: { redemptionItemId },
    });
};

export const loadRedemptionItemAsync = (adminUserToken, redemptionItemId) => {
    return (dispatch) => {
        redemptionItemQuery(adminUserToken, redemptionItemId).then(
            (response) => {
                if (response.data.errors == null) {
                    dispatch(
                        loadRedemptionItem(response.data.data.redemptionItem)
                    );
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    }
                }
            }
        );
    };
};
