import { updateObject } from "../utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  sponsors: null,
  productType: null,
  countries: null
};

const lookupReducer = (state = initialState, action) => {
    // console.log("lookupReducer");

    let updatedStateSlice = {};

    switch (action.type) {
        case actionTypes.LOAD_SPONSORS:
            updatedStateSlice = {
                sponsors: action.payload,
            };
            break;

        case actionTypes.LOAD_PRODUCT_TYPES:
            updatedStateSlice = {
                productTypes: action.payload,
            };
            break;

        case actionTypes.LOAD_COUNTRIES:
            updatedStateSlice = {
                countries: action.payload,
            };
            break;

        default:
    }

    return updateObject(state, updatedStateSlice);
};

export default lookupReducer;
