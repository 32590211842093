import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

export const uploadRedemptionItemProfilePhotoReset = () => {
    return {
        type: actionTypes.UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_RESET,
    };
};

const uploadRedemptionItemProfilePhotoSuccess = (uploadedFileName) => {
    return {
        type: actionTypes.UPLOAD_REDEMPTION_ITEM_PROFILE_PHOTO_SUCCESS,
        payload: uploadedFileName,
    };
};

export const uploadRedemptionItemProfilePhotoAsync = (
    adminUserToken,
    uploadRedemptionItemProfilePhotoPayload
) => {
    // console.log("uploadRedemptionItemProfilePhotoAsync");
    // console.log(uploadRedemptionItemProfilePhotoPayload);

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";

    return (dispatch) => {
        dispatch(uploadRedemptionItemProfilePhotoReset());

        axiosInstance
            .post(
                "api/image/uploadRedemptionItemProfilePhoto",
                uploadRedemptionItemProfilePhotoPayload
            )
            .then((response) => {
                if (response.data.errors == null) {
                    dispatch(
                        uploadRedemptionItemProfilePhotoSuccess(
                            response.data.data
                        )
                    );
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    } else {
                        uploadRedemptionItemProfilePhotoReset();
                    }
                }
            });
    };
};
