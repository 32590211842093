import { updateObject } from "../utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const memberReducer = (state = initialState, action) => {
    let updatedStateSlice = {};

    switch (action.type) {
        case actionTypes.LOAD_MEMBERS:
            updatedStateSlice = {
                members: action.payload,
            };
            break;
        // case actionTypes.SELECT_MEMBER:
        //     updatedStateSlice = {
        //         selectedMember: action.payload,
        //     };
        //     break;

        // case actionTypes.ADD_MEMBER:
        //     updatedStateSlice = {
        //     };
        //     break;

        default:
    }

    return updateObject(state, updatedStateSlice);
};

export default memberReducer;
