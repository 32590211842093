import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadCampaigns = (campaigns) => {
    return {
        type: actionTypes.LOAD_CAMPAIGNS,
        payload: campaigns,
    };
};

const CAMPAIGNS_QUERY_TEMPLATE = `
query Campaigns {
  campaigns {
    campaignId
    name
    image
    imageUrl
    startDate
    endDate
    campaignStatus {
        campaignStatusName
    }
  }
}`;

const campaignsQuery = (adminUserToken) => {
    // console.log("campaignsQuery");
    
    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: CAMPAIGNS_QUERY_TEMPLATE,
    });
};

export const loadCampaignsAsync = (adminUserToken) => {
    return (dispatch) => {
        campaignsQuery(adminUserToken)
            .then(response => {
                
                if (response.data.errors == null) {
                    dispatch(loadCampaigns(response.data.data.campaigns));
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (response.data.errors.some(x => x.message === INVALID_TOKEN)) {
                        dispatch(logout());
                    }
                }
            });
    };
};
