import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const updateRedemptionItemSuccess = (redemptionItemId) => {
    return {
        type: actionTypes.UPDATE_REDEMPTION_ITEM_SUCCESS,
        payload: redemptionItemId,
    };
};

const updateRedemptionItemError = (message) => {
    return {
        type: actionTypes.UPDATE_REDEMPTION_ITEM_ERROR,
        payload: message,
    };
};

export const updateRedemptionItemReset = () => {
    return {
        type: actionTypes.UPDATE_REDEMPTION_ITEM_RESET,
    };
};

const UPDATE_REDEMPTION_ITEM_MUTATION_TEMPLATE = `
mutation RedemptionItemUpdate($redemptionItemDetails: RedemptionItemUpdateInput!) {
    redemptionItem: redemptionItemUpdate(redemptionItemDetails: $redemptionItemDetails) {
        redemptionItemId
    }
}`;

const updateRedemptionItemMutation = (adminUserToken, redemptionItem) => {
    // console.log("updateRedemptionItemMutation");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: UPDATE_REDEMPTION_ITEM_MUTATION_TEMPLATE,
        variables: { redemptionItemDetails: redemptionItem },
    });
};

export const updateRedemptionItemAsync = (adminUserToken, redemptionItem) => {
    return (dispatch) => {
        updateRedemptionItemMutation(adminUserToken, redemptionItem).then(
            (response) => {
                // console.log(response);
                if (response.data.errors == null) {
                    dispatch(
                        updateRedemptionItemSuccess(
                            response.data.data.redemptionItem.redemptionItemId
                        )
                    );
                } else {
                    console.error(JSON.stringify(response.data.errors));
                    if (
                        response.data.errors.some(
                            (x) => x.message === INVALID_TOKEN
                        )
                    ) {
                        dispatch(logout());
                    } else {
                        dispatch(
                            updateRedemptionItemError(response.data.errors)
                        );
                    }
                }
            }
        );
    };
};
