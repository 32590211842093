import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadCampaign = (campaign) => {
    return {
        type: actionTypes.LOAD_CAMPAIGN,
        payload: campaign,
    };
};

const CAMPAIGN_QUERY_TEMPLATE = `
query Campaign (
    $campaignId: Int!
){
  campaign(campaignId: $campaignId) {
    campaignId
    name
    description
    image
    imageUrl
    startDate
    endDate
    campaignStatusId
    campaignStatus {
        campaignStatusName
    }
    sponsorId
    sponsor {
      sponsorId
      name
    }
    countryId
    country {
      countryId
      name
    }
    creditTopContributorConfig
  }
}`;

const campaignQuery = (adminUserToken, campaignId) => {
    // console.log("campaignQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: CAMPAIGN_QUERY_TEMPLATE,
        variables: { campaignId },
    });
};

export const loadCampaignAsync = (adminUserToken, campaignId) => {
    return (dispatch) => {
        campaignQuery(adminUserToken, campaignId).then((response) => {
            if (response.data.errors == null) {
                dispatch(loadCampaign(response.data.data.campaign));
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                }
            }
        });
    };
};
