import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";
import { ADMIN_USER_TOKEN_KEY, INVALID_TOKEN } from "../../helpers/constants";
import { logout } from "./logout";

const loadCountries = (countries) => {
    return {
        type: actionTypes.LOAD_COUNTRIES,
        payload: countries,
    };
};

const COUNTRIES_QUERY_TEMPLATE = `
query Countries {
  countries {
    countryId
    code
    name
  }
}`;

const countriesQuery = (adminUserToken) => {
    // console.log("countriesQuery");

    axiosInstance.defaults.headers.common[
        ADMIN_USER_TOKEN_KEY
    ] = adminUserToken;
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: COUNTRIES_QUERY_TEMPLATE,
    });
};

export const loadCountriesAsync = (adminUserToken) => {
    return (dispatch) => {
        countriesQuery(adminUserToken).then((response) => {
            if (response.data.errors == null) {
                dispatch(loadCountries(response.data.data.countries));
            } else {
                console.error(JSON.stringify(response.data.errors));
                if (
                    response.data.errors.some(
                        (x) => x.message === INVALID_TOKEN
                    )
                ) {
                    dispatch(logout());
                }
            }
        });
    };
};
