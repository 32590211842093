import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios/axios";

const login = (loggedInAdminUser) => {
    // console.log("login");

    return {
        type: actionTypes.LOGIN,
        payload: loggedInAdminUser,
    };
};

const loginError = (errors) => {
    // console.log("loginError");

    return {
        type: actionTypes.LOGIN_ERROR,
        payload: errors,
    }
}

const LOGIN_MUTATION_TEMPLATE = `
mutation (
    $email: String!, 
    $password: String!
) {
  adminUserLogin(loginCredentials: {  
        email: $email,
        password: $password
    })
  {
    token
    adminUser {
      createdDate
      email
      firstName
      lastLoginDate
      lastName
      adminUserId
      updatedDate
    }
  }  
}`;

const loginMutation = (email, password) => {
    // console.log("loginMutation");
    // console.log(email);
    // console.log(password);

    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    return axiosInstance.post("graphql", {
        query: LOGIN_MUTATION_TEMPLATE,
        variables: { email, password },
    });
}

export const loginAsync = (loginCredentials) => {
    return (dispatch, getState) => {

        // console.log("loginAsync");

        // Make Http call here and add .then(dispatch(login(loginCredentials)));
        loginMutation(loginCredentials.userName, loginCredentials.password).then(response => {
            // console.log(response);

            if (response.data.errors == null) {
                localStorage.setItem(
                    "loggedInAdminUser",
                    JSON.stringify(response.data.data.adminUserLogin)
                );
                dispatch(login(response.data.data.adminUserLogin));
            }
            else {
                console.error(JSON.stringify(response.data.errors));
                dispatch(loginError(response.data.errors));
            }
        });
    };
};

export const autoLogin = () => {
    return (dispatch) => {
        // console.log("autoLogin");

        const loggedInAdminUser = localStorage.getItem("loggedInAdminUser");
        if (loggedInAdminUser != null) {
            dispatch(login(JSON.parse(loggedInAdminUser)));
        }
    }
}